import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Badge, Menu, Button, Tooltip, Divider, List, ListItem } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/user-actions';
import * as authenticationAction from '../../actions/authentication-actions';
import { history } from '../../App';

class SidebarUserbox extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      anchorEl: null,
      profilePicture: null
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount(){
    this.props.userActions.getUserDetails();
    /* this.setState({profilePicture: this.props.userDetails.profilePicture}) */
  }

  /* componentDidUpdate(prevProps){
    if(this.props.userDetails && prevProps.userDetails!==this.props.userDetails){
      this.setState({profilePicture: null}, () => {
        this.setState({profilePicture: this.props.userDetails.profilePicture})
      })
    }
  } */

  handleClick(event){
    this.setState({ anchorEl: event.currentTarget});
  }

  handleClose(){
    this.setState({ anchorEl: null});
  }

  render(){
    return (
      <>
        <div className="app-sidebar--userbox">
          <Box className="card-tr-actions">
            <Button
              variant="text"
              onClick={this.handleClick}
              className="ml-2 p-0 d-30 border-0 btn-transition-none text-white-50"
              disableRipple>
              <FontAwesomeIcon
                icon={['fas', 'ellipsis-h']}
                className="font-size-lg"
              />
            </Button>
            <Menu
              anchorEl={this.state.anchorEl}
              keepMounted
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={Boolean(this.state.anchorEl)}
              classes={{ list: 'p-0' }}
              onClose={this.handleClose}>
              {/* <div className="dropdown-menu-lg overflow-hidden p-0">
                <div className="align-box-row align-items-center p-3">
                  <div className="avatar-icon-wrapper avatar-icon-md">
                    <div className="avatar-icon rounded-circle">
                      <img alt="..." src={avatar2} />
                    </div>
                  </div>
                  <div className="pl-2">
                    <span className="font-weight-bold d-block">Emma Taylor</span>
                    <div className="badge badge-success border-0">Active</div>
                  </div>
                </div>
                <Divider className="w-100" />
                <div className="d-flex py-3 justify-content-center">
                  <div className="d-flex align-items-center">
                    <div>
                      <FontAwesomeIcon
                        icon={['far', 'user']}
                        className="font-size-xxl text-success"
                      />
                    </div>
                    <div className="pl-3 line-height-sm">
                      <b className="font-size-lg">14,596</b>
                      <span className="text-black-50 d-block">reports</span>
                    </div>
                  </div>
                </div>
                <Divider className="w-100" />
                <div className="d-block rounded-bottom py-3 text-center">
                  <Tooltip arrow title="Facebook">
                    <Button
                      size="large"
                      className="btn-facebook mx-1 p-0 d-40 font-size-lg text-white">
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['fab', 'facebook']} />
                      </span>
                    </Button>
                  </Tooltip>
                  <Tooltip arrow title="Twitter">
                    <Button
                      size="large"
                      className="btn-twitter mx-1 p-0 d-40 font-size-lg text-white">
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                      </span>
                    </Button>
                  </Tooltip>
                </div>
              </div> */}
              <List
            component="div"
            className="nav-neutral-primary text-left d-block px-3 pb-3">
            {/* <ListItem button className="d-block text-left">
              My Account
            </ListItem> */}
            <NavLink to='/change-password'>
              <ListItem button className="d-block text-left">
                Reset Password
              </ListItem>
            </NavLink>
            <NavLink to='/profile'>
              <ListItem button className="d-block text-left" onClick={()=>{this.props.authenticationAction.logout('/login')}}>
                Logout
              </ListItem>
            </NavLink>
          </List>
            </Menu>
          </Box>
          <div className="avatar-icon-wrapper avatar-icon-md">
            <Badge
              variant="dot"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              badgeContent=" "
              overlap="circle"
              classes={{ badge: 'bg-success badge-circle' }}>
              <div className="avatar-icon rounded-circle">
                {/* <img alt="..." src={avatar2} /> */}
                {this.props.userDetails && this.props.userDetails.profilePicture ? <img src={this.props.userDetails.profilePicture} alt='profilePicture'/> : <AccountCircleIcon/>}
              </div>
            </Badge>
          </div>
          <div className="my-3 userbox-details">
            {/* <span>Emma Taylor</span> */}
            <small className="d-block text-white-50">
              {this.props.userDetails && (this.props.userDetails.firstName ? this.props.userDetails.firstName : ((this.props.userDetails.google && this.props.userDetails.google.email) ? this.props.userDetails.google.email : this.props.userDetails.local.email))}
            </small>
          </div>
          <Button
            size="small"
            onClick={() => {history.push('/profile'); this.props.onClicked()}}
            className="btn-userbox">
            View profile
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.userReducer.userDetails,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    authenticationAction: bindActionCreators(authenticationAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarUserbox);
