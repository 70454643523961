import { Box, Container, makeStyles, Typography } from "@material-ui/core";

import React from "react";

const useStyles = makeStyles({
  flex: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "12px",
  },
  heading3: {
    fontSize: "24px",
    fontWeight: "700",
    marginBlock: "10px",
    color: "#3b3e66",
  },
  paragraph: {
    fontSize: "20px",
    color: "rgba(59, 62, 102, 0.5)",
  },
  boldText: {
    fontSize: "20px",
    fontWeight: "700",
  },
  spacing: {
    padding: "5px 0px",
  },
});

export default function PrivacyPolicy() {
  const classes = useStyles();
  return (
    <Container fixed>
      <Box className={classes.flex}>
        <Typography variant="h1" component="h1" style={{ fontSize: 'clamp(32px, 4vw, 48px)', color: "#070919" }}>
          Wilson A.I. Privacy Policy
        </Typography>
        <Box>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            Our privacy commitment
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            Wilson A.I. is committed to protecting the privacy of your personal information. This policy explains how
            Wilson A.I. manages the personal information that we collect, use and disclose and how to contact us if you
            have any further queries about our management of your personal information. This policy does not cover
            personal information collected or held by Wilson A.I. Pty Ltd or any of its related bodies corporate (as
            applicable) about its employees.
          </Typography>
          <br />
          <Typography component="p" className={classes.paragraph}>
            Wilson A.I. is bound by the Australian Privacy Principles in the Privacy Act 1988 (Cth) (and applicable
            State and Territory health privacy legislation), which regulate how we may collect, use, disclose and store
            personal information, and how individuals may access and correct personal information held about them.
            Wilson A.I. is also required to comply with the Spam Act 2003 (Cth).
          </Typography>
          <br />
          <Box className={classes.paragraph}>
            In this policy ‘we’, ‘us’, ‘our’ and ‘Wilson A.I.’ refers to (and this policy applies to), the following
            Wilson A.I. businesses:
            <ul>
              <li>Wilson A.I. Pty Ltd</li>
              <li>Wilson A.I. Holdings Pty Ltd</li>
            </ul>
          </Box>
          <Typography component="p" className={classes.paragraph}>
            Please contact us if you have any questions regarding Wilson A.I.’s Privacy Policy or would like us to send
            you a copy of this policy please contact our Privacy Officer using the details provided below. Our Privacy
            Policy may be amended from time to time. The current version will be posted on our website.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            What is personal information?
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            <Typography component="span" className={classes.boldText}>
              {" "}
              Personal information
            </Typography>{" "}
            is any information or an opinion, in any form and whether true or not, about an identified individual or an
            individual who is reasonably identifiable. Examples include an individual’s name, address, contact number
            and email address.
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            <Typography component="span" className={classes.boldText}>
              {" "}
              Sensitive information
            </Typography>{" "}
            is a special category of personal information. Sensitive information includes health information and
            information about a persons’ race, ethnic origin, political opinions, membership of political, professional
            or trade associations, religious or philosophical beliefs, sexual preferences and criminal history.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            How does Wilson A.I. collect and hold your personal information?
          </Typography>

          <Box className={classes.paragraph}>
            Wilson A.I. will only collect personal information about you by lawful and fair means. Wilson A.I. collects
            personal information in a variety of ways. It is Wilson A.I.’s usual practice to collect personal
            information directly from you (or from your authorised representative), for example when:
            <ul>
              <li>
                You enquire about or seek a quotation for a product or service offered by Wilson A.I. or our third party
                suppliers
              </li>
              <li>
                You purchase a product or service offered by an Wilson A.I. business and or our third party suppliers
              </li>
              <li>You contact us by telephone, via mail, e-mail or online</li>
              <li>You visit our website</li>
              <li>We supply any other products or services to you</li>
              <li>
                You enter into any trade promotions, competitions, specials or other offers with Wilson A.I. or its
                direct partners.
              </li>
            </ul>
          </Box>
          <Typography component="p" className={classes.paragraph}>
            Sometimes we collect personal information about customers or potential customers from a third party or a
            publicly available source, or from marketing and similar lists legally acquired by us. If you provide us
            with personal information about another person (such as a family member), please make sure that you tell
            them about this policy.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            What kinds of personal information does Wilson A.I. collect and hold?
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            The kinds of personal information that we collect and hold about you will depend on the circumstances of
            collection, including whether we collect the information from you as a customer, provider, contractor,
            stakeholder, job applicant or in some other capacity.
          </Typography>
          <br />
          <Typography component="p" className={classes.paragraph}>
            For example, if you request a statement of work for a product or service offered by Wilson A.I., we may
            collect your name, address, email address, telephone number, date of birth and any other information
            required to provide you withthe statement of work. If you deal with us in some other capacity (for example,
            as a provider, a supplier or a stakeholder), we may collect your name, contact details and any other
            information you choose to provide to us. Any personal information that you provide via our website is
            collected and stored by Wilson A.I.
          </Typography>
          <br />
          <Typography component="p" className={classes.paragraph}>
            If you provide your personal information to a third party via Wilson A.I. (for example, to purchase products
            or services on third party website which is linked to Wilson A.I.) that information is collected and managed
            by those third parties. You should familiarise yourself with their privacy policy prior to deciding whether
            you wish to provide them with your information.
          </Typography>
          <br />
          <Typography component="p" className={classes.paragraph}>
            We may monitor and record calls that you make to us in certain circumstances. For example, calls to our
            contact centre are monitored and recorded for training and customer service purposes and to meet our
            regulatory obligations. Some of the products offered by our providers can cover more than one person (for
            example, family and couples health insurance policies that cover two or more persons). If you provide us
            with information about your partner or a dependant on the policy, you must obtain that person’s permission
            to give us the information and inform them of this policy. If we are not able to collect your personal
            information, we may not be able to provide you with our products or services or do business with you or the
            organisation with which you are connected.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            How does Wilson A.I. use your personal information?
          </Typography>
          <Box className={classes.paragraph}>
            We use personal information for a variety of reasons including to:
            <ul>
              <li>Provide services and products to our customers.</li>
              <li>Answer your inquiries and deliver customer service.</li>
              <li>
                Tell our customers and prospective customers about other products, services or promotional offers that
                we think may be of interest to them
              </li>
              <li>Ensure we comply with the requirements of our providers.</li>
              <li>Maintain and improve our customer service.</li>
              <li>Meet our regulatory and legal obligations.</li>
              <li>Manage and resolve any legal or commercial complaints and issues.</li>
              <li>Consider job applicants for current and future employment.</li>
              <li>Manage our relationships with our providers, suppliers and stakeholders.</li>
              <li>Carry out internal functions including training</li>
              <li>Conduct marketing research and analysis.</li>
            </ul>
          </Box>
          <Typography component="p" className={classes.paragraph}>
            We may also use your information for other purposes required or authorised by or under law, including
            purposes for which you have provided your consent. That consent may be written, verbal or implied from your
            conduct.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            In what circumstances will Wilson A.I. disclose your personal information?
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            In the course of conducting our business and providing our products and services to you, we may disclose
            your personal information. In particular, your personal information may be shared between the Wilson A.I.
            businesses for the purposes outlined in this policy.
          </Typography>
          <br />
          <Box className={classes.paragraph}>
            We may also disclose your personal information to, where appropriate:
            <ul>
              <li>
                If you are a customer, our providers (before deciding if you want to provide your personal information
                to any of our providers directly or via our website links, you should read their privacy policy).
              </li>
              <li>Professional advisers (such as lawyers or auditors).</li>
              <li>
                Our contracted service providers, including.
                <ul>
                  <li>Information technology service providers.</li>
                  <li>Payment systems operators and financial institutions.</li>
                  <li>
                    Organisations authorised by Wilson A.I. to conduct promotional, research or marketing activities.
                  </li>
                </ul>
              </li>
              <li>Law enforcement agencies or government authorities.</li>
              <li>
                Any persons acting on your behalf including those persons nominated by you, executors, trustees and
                legal representatives.
              </li>
            </ul>
          </Box>
          <Typography component="p" className={classes.paragraph}>
            We may disclose your personal information to third parties for other purposes required or authorised by or
            under law, including purposes for which you have provided your consent. That consent may be written, verbal
            or implied from your conduct. In all circumstances where your personal information is disclosed, we will
            take all steps reasonable to ensure that these third parties undertake to protect your privacy.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            Direct marketing
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            If you are a customer or a potential customer, from time to time we may contact you with information about
            products and services offered by Wilson A.I. and our business partners, which we think may be of interest to
            you. When we contact you it may be by mail, telephone, email, SMS or social media. We will generally give
            you the opportunity to express a wish not to receive direct marketing communications. Your consent to
            receive direct marketing communications in the above ways will be deemed to have been given if you do not
            opt out of receiving direct marketing communications from us, and will remain current for an indefinite
            period of time unless and until you advise us otherwise. You can opt-out of receiving direct marketing at
            any time (see below).
          </Typography>
          <br />
          <Typography component="p" className={classes.paragraph}>
            If you want to know how we obtained your personal information, please contact our Privacy Officer using the
            details provided below and we will let you know unless it is impracticable or unreasonable to do so.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            How can you opt out of receiving direct marketing?
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            You can let us know at any time if you do not want the personal information we hold about you to be used or
            disclosed for direct marketing purposes, or you do not wish to be contacted in any of the above ways, by
            contacting us. In some circumstances we may need to contact you to obtain additional information, to verify
            your identity or to clarify your request.
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            If you wish to unsubscribe from future marketing and promotional material, please email “Unsubscribe” to{" "}
            <Typography component="span" className={classes.boldText}>
              contact@wilsonai.com
            </Typography>
          </Typography>

          <Box className={classes.spacing}>
            <Typography variant="h3" component="h3" className={classes.heading3}>
              Does Wilson A.I. disclose your personal information to overseas recipients?
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              Some of our service providers are located overseas. As a result, Wilson A.I. may disclose your personal
              information to overseas recipients, for example, sometimes we use third party platforms to deliver or
              store information. These are sites hosted and managed by organisations other than ourselves, your personal
              information may be stored in a secure and encrypted form overseas (e.g. in data storage and cloud
              computing facilities operated by us (or by third parties on Wilson A.I.’s behalf).
            </Typography>
          </Box>
          <Box className={classes.spacing}>
            <Typography variant="h3" component="h3" className={classes.heading3}>
              Use of Government Identifiers
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              Wilson A.I. will not use Australian Commonwealth government-related identifiers, such as Medicare numbers,
              or a driver’s license number, as its own identifier of individuals.
            </Typography>
          </Box>
          <Box className={classes.spacing}>
            <Typography variant="h3" component="h3" className={classes.heading3}>
              Data quality and Security
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              We may store your information in hardcopy and/or in electronic form. We will take reasonable steps to
              ensure that your personal information is protected from misuse, interference and loss and from
              unauthorised access, modification or disclosure.
            </Typography>
            <br />
            <Typography component="p" className={classes.paragraph}>
              This may include a range of systems and communication security measures, as well as the secure storage of
              hard copy documents. In addition, access to your personal information will be restricted to those properly
              authorised to have access.
            </Typography>
            <br />
            <Typography component="p" className={classes.paragraph}>
              We rely on the personal information we hold in conducting our business. Therefore, it is very important
              that the information we hold is accurate, complete and up-to-date. If you believe that your personal
              information is inaccurate, incomplete, out of date, and irrelevant or misleading, please contact our
              Privacy Officer using the details provided below and we will take reasonable steps to correct that
              information.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.spacing}>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            Website usage and Cookies
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            Our website is professionally hosted and operates in a secure environment. You should however be aware that
            there is always an inherent risk in transmitting your personal information via the Internet.
          </Typography>
          <br />
          <Typography component="p" className={classes.paragraph}>
            Cookies are pieces of information that a website downloads to your computer to record your preferences while
            you browse. Other technologies that may be used by Wilson A.I. include web beacons (which may operate in
            conjunction with cookies). The information collected through these technologies may be used by Wilson A.I.
            to improve and customize your experience on our website. In addition, we collect aggregated non-personally
            identifiable data from our website to help us maintain and improve delivery of the Wilson A.I. online
            service and marketing. This aggregated information assists Wilson A.I. to improve content and website
            navigation. You can configure your web browser to reject and block cookies. If you decide to delete or block
            certain cookies, your experience on our website may be limited.
          </Typography>
          <br />
          <Box className={classes.paragraph}>
            When you visit any of our online resources, our system may collect information about your visit for
            statistical purposes, including:
            <ul>
              <li>Server address.</li>
              <li>Top level domain name (for example .com, .gov, .au, .uk etc.).</li>
              <li>The date and time of your visit to the site.</li>
              <li>The pages you accessed, and documents downloaded during your visit.</li>
              <li>The previous site you visited.</li>
              <li>If you’ve visited our site before.</li>
              <li>The type of browser used.</li>
            </ul>
          </Box>
          <Typography component="p" className={classes.paragraph}>
            We record this information to maintain our server and improve our services. There are several methods and
            packages that we use to collect visitor behaviours on each of our online platforms. Like a number of
            commercial web site operators, Wilson A.I. use tools to measure and analyse internet usage across our web
            sites. This enables Wilson A.I. to track the number of unique browsers to our web sites.
          </Typography>
          <br />
          <Typography component="p" className={classes.paragraph}>
            In some cases, we allow third-party vendors to use cookies and web beacon to provide you with information
            about our services and products. These technologies may be used in regards to activities like surveys,
            website analytics, online behavioural advertising and email campaign management. There are ways for you to
            opt out of third-party cookies. (i) by contacting Wilson A.I. or (ii) visiting youronlinechoices.com.au that
            allows you to opt out of some online behavioural advertising. These services provided by third party vendors
            generally operate without the collection or use of any personal information. In many cases, the information
            relates only to a device or is of a statistical nature, and we will not be able to identify the user.
          </Typography>
        </Box>
        <Box className={classes.spacing}>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            Links to third party sites
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            Whilst links to third party websites are provided on our website, we are not responsible for the content or
            practices of these third party websites.
          </Typography>
          <br />
          <Typography component="p" className={classes.paragraph}>
            These links are provided for your convenience and do not represent Wilson A.I.’s endorsement of any linked
            third party website. We recommend that you review the privacy policies of these third parties prior to
            providing them with your personal information.
          </Typography>
        </Box>
        <Box className={classes.spacing}>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            How you can access or correct your personal information
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            You can request access to the personal information we hold about you by contacting our Privacy Officer using
            the details provided below and we will generally provide you with access, subject to some exceptions
            permitted by law
          </Typography>
          <br />
          <Box className={classes.paragraph}>
            Access to personal information may be refused in a number of circumstances, such as where:
            <ul>
              <li>Providing access would have an unreasonable impact on the privacy of others.</li>
              <li>The request is frivolous or vexatious.</li>
              <li>
                The information relates to existing or anticipated legal proceedings and would not normally be
                accessible as part of those proceedings.
              </li>
              <li>Providing access would be unlawful.</li>
              <li>Denying access is required by law.</li>
              <li>
                Providing access would be likely to prejudice enforcement related activities conducted by an enforcement
                body.
              </li>
              <li>
                Giving access would reveal evaluative information in connection with a commercially sensitive
                decision-making process.
              </li>
              <li>
                Access would pose a threat to the life, health or safety of any individual or to public health or
                safety.
              </li>
            </ul>
          </Box>
          <Typography component="p" className={classes.paragraph}>
            If we deny or restrict access to the information you have requested, we will give you written reasons for
            this decision when we respond to your request. We may charge a fee to cover our costs of providing access to
            you and will advise you of any fee payable before we provide you with access.
          </Typography>
        </Box>
        <Box className={classes.spacing}>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            How you can make a privacy complaint
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            Please contact our Privacy Officer using the details provided below if you have any concerns or complaints
            about the manner in which we have collected or handled your personal information. We will investigate your
            complaint and respond to you in writing within 30 days.
          </Typography>
          <br />
          <Typography component="p" className={classes.paragraph}>
            If you consider your privacy concerns have not been resolved satisfactorily by us, or you wish to obtain
            more information on privacy requirements, you can contact the Australian Information Commissioner on 1300
            363 992 or visit their website at{" "}
            <a target="_blank" href="https://www.oaic.gov.au/" style={{ textDecoration: "underline", color: "blue" }}>
              {" "}
              www.oaic.gov.au.
            </a>
          </Typography>
        </Box>
        <Box className={classes.spacing}>
          <Typography variant="h3" component="h3" className={classes.heading3}>
            How to contact us
          </Typography>
          <Box className={classes.paragraph}>
            If you:
            <ul>
              <li>
                Have queries, concerns or complaints about the manner in which your personal information has been
                collected or handled by Wilson A.I.
              </li>
              <li>
                Would like to request a hard copy of this privacy policy or discuss any issues relating to this policy
                or privacy matters generally.
              </li>
              <li>Would like to request access to or correction of the personal information we hold about you.</li>
            </ul>
          </Box>
          <Typography component="p" className={`${classes.paragraph} ${classes.boldText}`}>
            Please contact our Privacy Officer:
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            Privacy Officer
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            31 Hooper Grove
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            Mount Martha VIC 3934{" "}
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            <Typography component="span" className={classes.boldText}>
              {" "}
              Email:
            </Typography>{" "}
            <a href="mailto:contact@wilsonai.com" style={{ textDecoration: "underline", color: "blue" }}>
              {" "}
              contact@wilsonai.com{" "}
            </a>
          </Typography>
          <Typography component="p" className={`${classes.paragraph} ${classes.boldText}`}>
            Updated 26th August 2019{" "}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}