import React from 'react';
import { Grid, Button, TextField, Checkbox  } from '@material-ui/core';
import { EMAIL_PATTERN } from '../../App.config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authenticationActions from '../../actions/authentication-actions';

class Register extends React.Component{
    constructor(props){
        super(props);
        this.state={
            inputFields: {
                email: "",
                password: "",
            },
            validationErrors: {
                email: "",
                password: "",
            },
            isSubmitClicked: false,
            isAllValid: false,

            termsChecked: false,
        };
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkErrors = this.checkErrors.bind(this);

        this.handleTermsToggle = this.handleTermsToggle.bind(this);
        this.handelEnterFrom = this.handelEnterFrom.bind(this);
    }

    componentDidMount(){
        /* window.addEventListener('keydown', (e) => {
            if (e.keyCode === 13) {
                this.state.termsChecked && this.handleSubmitClick();
                e.target.removeEventListener('keydown', () => {})
            }
        }); */
    }

    handelEnterFrom(e) {
      if (e.keyCode === 13) {
          this.handleSubmitClick();
      }
  }

    handleInputChange(event){
        let name = event.target.name;
        let value = event.target.value.trim();
        let inputObject = this.state.inputFields;
        inputObject[name] = value;
        this.setState({ inputFields: inputObject }, ()  => {
            this.state.isSubmitClicked && this.validateFields();
        });
    }

    checkErrors(callbackFunction){
        let doErrorExist = false;
        Object.values(this.state.validationErrors).map(error => {
            if(error){
                doErrorExist = true;
            }
            return null;
        });
        this.setState({ isAllValid: !doErrorExist }, () => {
            callbackFunction && callbackFunction();
        });
    }

    handleSubmitClick(event){
        event && event.preventDefault();
        this.setState({ isSubmitClicked: true });
        this.validateFields(() => {
            if(this.state.isAllValid && this.state.termsChecked){
              this.props.authenticationActions.register(this.state.inputFields.email, this.state.inputFields.password);
            }
        });
    }

    validateFields(callbackFunction){
        let inputObject = this.state.inputFields;
        let inputErrorObject = this.state.validationErrors;
        Object.keys(inputObject).map(fieldName => {
            if(!inputObject[fieldName]){
                inputErrorObject[fieldName] = "Field cannot be empty";
                return null;
            }
            else{
              switch(fieldName){
                case "password":
                    inputErrorObject[fieldName] = inputObject[fieldName].length<6 ? "Password must be atleast 6 characters long" : "";
                    break;
                case "email":
                    inputErrorObject[fieldName] = !EMAIL_PATTERN.test(inputObject[fieldName]) ? "Email not valid" : "";
                    break;
                default:
                    inputErrorObject[fieldName] = "";
              }
            }
            return null;
        });
        this.setState({ validationErrors: inputErrorObject }, () => {
            this.checkErrors(callbackFunction && callbackFunction);
        });
    }

    handleTermsToggle(event){
      this.setState({ termsChecked: event.target.checked})
    }

    render(){
        return(
          <div className="app-wrapper bg-white">
          <div className="app-main">
            <div className="app-content p-0">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-4">
                    <Grid item md={10} lg={8} xl={4} className="mx-auto">
                      <div className="text-center mb-4">
                        <h1 className="display-4 mb-1 font-weight-bold">
                          Create your account
                        </h1>
                        <p className="font-size-lg mb-0 text-black-50">
                          Start benefiting from our tools right away
                        </p>
                      </div>
                      <div className="mb-3">
                        <label className="font-weight-bold mb-2">
                          Email address
                        </label>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder="Enter your email address"
                          name="email"
                          onKeyDown={this.handelEnterFrom}
                          value={this.state.inputFields.email}
                          onChange={this.handleInputChange}
                          helperText={this.state.validationErrors.email} 
                          error={this.state.validationErrors.email ? true : false}
                        />
                      </div>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <label className="font-weight-bold mb-2">
                            Password
                          </label>
                        </div>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          placeholder="Enter your password"
                          type="password"
                          name="password" 
                          onKeyDown={this.handelEnterFrom}
                          value={this.state.inputFields.password}
                          onChange={this.handleInputChange}
                          helperText={this.state.validationErrors.password} 
                          error={this.state.validationErrors.password ? true : false}
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center font-size-md  mt-2">
                        <div></div>
                        <div>
                          <a
                            href="#/"
                            onClick={(e) => {e.preventDefault(); this.props.history.push('/login')}}
                            className="text-first">
                              Sign In
                          </a>
                        </div>
                      </div>
                      <div className="my-4 text-center">
                        By clicking the <strong>Create account</strong> button
                        below you agree to our <a className="text-info" href="/privacy-statement" target="_blank" >terms of service</a> and <a className="text-info" href="/privacy-statement" target="_blank">privacy statement</a>.
                        <div>
                          <Checkbox
                            checked={this.state.termsChecked}
                            onChange={this.handleTermsToggle}
                            onKeyDown={this.handelEnterFrom}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          /> Agree
                        </div>
                      </div>
                      <div>
                      </div>
                      <div className="text-center mb-2">
                        <Button className="btn-primary text-uppercase font-weight-bold font-size-sm my-3" disabled={this.state.termsChecked ? false : true} onClick={this.handleSubmitClick}>
                          Create account
                        </Button>
                      </div>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
