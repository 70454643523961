import React, {useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import MapIcon from '@material-ui/icons/Map';
import SidebarUserbox from '../SidebarUserbox';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import { ADMIN, PRINCIPAL_AGENT, AGENT, INDIVIDUAL_AGENT} from '../../App.config';
import { Hidden, Collapse } from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import clsx from 'clsx';
import RoomIcon from '@material-ui/icons/Room';
import market from '../../assets/images/sidebar/market.png';
import marketDetail from '../../assets/images/sidebar/marketDetail.png';
import invite from '../../assets/images/sidebar/invite.png';
import map from '../../assets/images/sidebar/map.png';
import withdrawn from '../../assets/images/sidebar/withdrawn.png';
import withdrawnDetail from '../../assets/images/sidebar/withdrawnDetail.png'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;
  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [reportsOpen, setReportsOpen] = useState(false);
  const toggleReports = (event) => {
    setReportsOpen(!reportsOpen);
    event.preventDefault();
  };
  
  return (
    <>
      <PerfectScrollbar>
       <SidebarUserbox onClicked={toggleSidebarMobile}/>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Navigation menu</span>
          </div>
          <ul>
            {true &&
              <>
              {/* {props.userDetails && props.userDetails.planDetails && !props.userDetails.roles.includes(ADMIN) && <li>
                <NavLink
                  activeClassName="active"
                  onClick={toggleSidebarMobile}
                  className="nav-link-simple"
                  to="/dashboard">
                  <span className="sidebar-icon">
                    <AssessmentIcon />
                  </span>
                  Dashboard
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRightTwoToneIcon />
                  </span>
                </NavLink>
              </li>} */}
              {props.userDetails && props.userDetails.roles.includes(ADMIN) && <li>
                <NavLink
                  activeClassName="active"
                  onClick={toggleSidebarMobile}
                  className="nav-link-simple"
                  to="/admin-global-postcode-selection">
                  <span className="sidebar-icon">
                    <RoomIcon />
                  </span>
                    Global Postcode Selection
                </NavLink>
              </li>}
              {props.userDetails && props.userDetails.roles.includes(ADMIN) && <li>
                <NavLink
                  activeClassName="active"
                  onClick={toggleSidebarMobile}
                  className="nav-link-simple"
                  to="/configure-application-postcodes">
                  <span className="sidebar-icon">
                    <RoomIcon />
                  </span>
                    Configure Application Postcodes
                </NavLink>
              </li>}
              {props.userDetails && props.userDetails.roles.includes(ADMIN) && <li>
                <NavLink
                  activeClassName="active"
                  onClick={toggleSidebarMobile}
                  className="nav-link-simple"
                  to="/postcode-request">
                  <span className="sidebar-icon">
                    <RoomIcon />
                  </span>
                    Postcode Request
                </NavLink>
              </li>}
              
              {/* {props.userDetails && props.userDetails.planDetails && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <li>
               <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/map">
                <span className="sidebar-icon">
                  <MapIcon />
                </span>
                Map
              </NavLink>
              <NavLink
               activeClassName="active"
               className="nav-link-simple"
               onClick={toggleSidebarMobile}
               to="/analysis-report">
                <span className='sidebar-icon'>
                 <img src={market}/>
                </span>
                Competitor and Market Report
              </NavLink>
            </li>} */}
            {props.userDetails && props.userDetails.planDetails && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/map">
                <span className="sidebar-icon">
                  <img src={map} />
                </span>
                Map
              </NavLink>
            </li>}

               {props.userDetails && props.userDetails.planDetails && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <li>
               {/* <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/map">
                <span className="sidebar-icon">
                  <MapIcon />
                </span>
                Map
              </NavLink>  */}
              <NavLink
               activeClassName="active"
               className="nav-link-simple"
               onClick={toggleSidebarMobile}
               to="/analysis-report">
                <span className='sidebar-icon'>
                 <img src={market}/>
                </span>
                Competitor and Market Report
              </NavLink>
            </li>} 
           {props.userDetails && props.userDetails.planDetails && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <li>
              <NavLink
               activeClassName="active"
               className="nav-link-simple"
               onClick={toggleSidebarMobile}
               to="/analysis-leaderboard-report">
                <span className='sidebar-icon'>
                 <img src={marketDetail} />
                </span>
                  Leaderbord Report
              </NavLink>
            </li>} 
            {props.userDetails && props.userDetails.planDetails && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <li>
              <NavLink
               activeClassName="active"
               className="nav-link-simple"
               onClick={toggleSidebarMobile}
               to="/analysis-detail-report">
                <span className='sidebar-icon'>
                 <img src={marketDetail} />
                </span>
                Competitor and Market Detail <br/> Report
              </NavLink>
            </li>}
              {/* {props.userDetails && props.userDetails.planDetails && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <li>
              <NavLink
               activeClassName="active"
               className="nav-link-simple"
               onClick={toggleSidebarMobile}
               to="/withdrawn-report">
                <span className='sidebar-icon'>
                 <img src={withdrawn} />
                </span>
                Withdrawn Property Report
              </NavLink>
            </li>} */}
             {/* {props.userDetails && props.userDetails.planDetails && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <li>
              <NavLink
               activeClassName="active"
               className="nav-link-simple"
               onClick={toggleSidebarMobile}
               to="/withdrawn-detail-report">
                <span className='sidebar-icon'>
                 <img src={withdrawnDetail} />
                </span>
                Withdrawn Property Detail <br/> Report
              </NavLink>
            </li>} */}
             {/* {props.userDetails && props.userDetails.planDetails && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/user-area-code-select">
                <span className="sidebar-icon">
                  <EditLocationIcon />
                </span>
                Post Codes
              </NavLink>
            </li>}  */}
            {props.userDetails && props.userDetails.planDetails && props.userDetails.roles.includes(PRINCIPAL_AGENT) && <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/invite-agent">
                <span className="sidebar-icon">
                  <img src={invite} />
                </span>
                Invite Agent
              </NavLink>
            </li>}
            {props.userDetails && props.userDetails.roles.includes(ADMIN) && <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/plan-listing">
                <span className="sidebar-icon">
                  <SubscriptionsIcon />
                </span>
                Plans
              </NavLink>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/invite-principle-agent">
                <span className="sidebar-icon">
                <PeopleAltIcon />
                </span>
                Invite Principle Agent
              </NavLink>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/assign-plan-to-user">
                <span className="sidebar-icon">
                  <SubscriptionsIcon />
                </span>
                Assign Plan To User
              </NavLink>
            </li>}
            {props.userDetails && props.userDetails.roles.includes(ADMIN) && <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/registered-users">
                <span className="sidebar-icon">
                  <PeopleAltIcon />
                </span>
                Registered Users
              </NavLink>
            </li>}
            {/* <li>
              <a
                href="#/"
                onClick={toggleReports}
                className={clsx({ active: reportsOpen })}>
                <span className="sidebar-icon">
                  <AssessmentIcon />
                </span>
                <span className="sidebar-item-label">Reports</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={reportsOpen}>
                <ul> */}
                 {/*  {props.userDetails && props.userDetails.roles && !props.userDetails.roles.includes(AGENT) && <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/plan-history">
                      Plan History
                    </NavLink>
                  </li>} */}
                  {/* {props.userDetails && props.userDetails.roles.length > 0 && <> */}
                  {/* <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/user-history">
                      User History
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink
                     onClick={toggleSidebarMobile}
                     to="/analysis-report">
                       Competitor and Market Report
                     </NavLink>
                  </li>
                  <li>
                    <NavLink
                     onClick={toggleSidebarMobile}
                     to="/withdrawal-report">
                       Withdrawal Property Report
                     </NavLink>
                    </li> 
                  </>}*/}
                {/* </ul>
              </Collapse>
            </li> */}
            </>
            }
          </ul>
          
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  userDetails: state.userReducer.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
