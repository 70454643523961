import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { IconButton } from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import homepageLayoutStyles from './styles';

import PropTypes from 'prop-types';

const HomepageLayout = (props) => {
  const { children, classes } = props;


  React.useEffect(() => {
  }, []);

  return <>
        {children}

  </>;
};

HomepageLayout.propTypes = {
  children: PropTypes.node
};

export default withStyles(homepageLayoutStyles)(HomepageLayout);
