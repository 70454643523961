import React from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authenticationActions from '../../actions/authentication-actions';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: {
        newPassword: '',
        confirmPassword: ''
      },
      validationErrors: {
        newPassword: '',
        confirmPassword: ''
      },
      verificationCode: '',
      isSubmitClicked: false,
      isAllValid: false
    };
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
  }

  componentDidMount() {
    this.props.authenticationActions.logout()
    window.scrollTo(0, 0);
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        this.handleSubmitClick();
        e.target.removeEventListener('keydown', () => {});
      }
    });
  }

  handleInputChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    let inputObject = this.state.inputFields;
    inputObject[name] = value;
    this.setState({ inputFields: inputObject }, () => {
      this.state.isSubmitClicked && this.validateFields();
    });
  }

  checkErrors(callbackFunction) {
    let doErrorExist = false;
    Object.values(this.state.validationErrors).map((error) => {
      if (error) {
        doErrorExist = true;
      }
      return null;
    });
    this.setState({ isAllValid: !doErrorExist }, () => {
      callbackFunction && callbackFunction();
    });
  }

  handleSubmitClick(event) {
    event && event.preventDefault();
    this.setState({ isSubmitClicked: true });
    this.validateFields(() => {
      if (this.state.isAllValid) {
        this.props.authenticationActions.resetPassword(this.state.inputFields.newPassword, this.props.match.params.verificationToken)
      }
    });
  }

  validateFields(callbackFunction) {
    let inputObject = this.state.inputFields;
    let inputErrorObject = this.state.validationErrors;
    Object.keys(inputObject).map((fieldName) => {
      if (!inputObject[fieldName]) {
        inputErrorObject[fieldName] = 'Field cannot be empty';
        return null;
      }
      else{
        switch(fieldName){
            case "newPassword":
                inputErrorObject[fieldName] = inputObject[fieldName].length<6 ? "Password must be atleast 6 characters long" : "";
                break;
            case "confirmPassword":
                inputErrorObject[fieldName] = inputObject[fieldName] !== inputObject.newPassword ? "Password do not match" : "";
                break;
        }
      }
      return null;
    });
    this.setState({ validationErrors: inputErrorObject }, () => {
      this.checkErrors(callbackFunction && callbackFunction);
    });
  }

  render() {
    return (
      <>
        <div className="app-wrapper bg-white min-vh-100">
          <div className="app-main min-vh-100">
            <div className="app-content p-0">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Grid item md={10} lg={8} xl={4} className="mx-auto">
                      <div className="text-center mb-5">
                        <h1 className="display-4 mb-1 font-weight-bold">
                          Reset Password
                        </h1>
                        <p className="font-size-lg mb-0 text-black-50">
                          Your new password is just a click away!
                        </p>
                      </div>
                      <div>
                        <label className="font-weight-bold mb-2">
                          Password
                        </label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="textfield-password"
                          name="newPassword"
                          type="password"
                          onChange={this.handleInputChange}
                          helperText={this.state.validationErrors.newPassword}
                          error={
                            this.state.validationErrors.newPassword
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div>
                        <label className="font-weight-bold mb-2">
                          Confirm your password
                        </label>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="textfield-confirmPassword"
                          name="confirmPassword"
                          type="password"
                          onChange={this.handleInputChange}
                          helperText={
                            this.state.validationErrors.confirmPassword
                          }
                          error={
                            this.state.validationErrors.confirmPassword
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="text-center mb-5">
                        <Button
                          fullWidth
                          onClick={this.handleSubmitClick}
                          className="text-uppercase font-weight-bold font-size-sm mt-4 btn-primary">
                          Reset password
                        </Button>
                      </div>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
