import { SET_SALES_PROPERTY_DATA, SET_MAP_FILTER_DATA, SET_RENTAL_PROPERTY_DATA, SET_PROPERTY_DATA_BY_ID, SET_ALL_PROPERTY_DATA, SET_FILTERED_ALL_PROPERTY_DATA, SET_SELECTED_TABLE_DATA, SET_OLD_FILTERED_ALL_PROPERTY_DATA } from '../actions/actionTypes';
  
export default function mapReducer(state = {
    salesPropertyData: null,
    mapFilterData: null,
    rentalPropertyData: null,
    propertyDataById: null,

    allPropertyData: null,
    filteredAllPropertyData: null,
    filterState: {},
    selectedTableData: null,
    oldFilteredPropertyData: null,
}, action) {
    switch (action.type) {
        case SET_SALES_PROPERTY_DATA:
            return Object.assign({}, state, {
                salesPropertyData: action.salesPropertyData,
            });
        case SET_MAP_FILTER_DATA:
            return Object.assign({}, state, {
                mapFilterData: action.mapFilterData,
            });
        case SET_RENTAL_PROPERTY_DATA:
            return Object.assign({}, state, {
                rentalPropertyData: action.rentalPropertyData,
            });
        case SET_PROPERTY_DATA_BY_ID:
            return Object.assign({}, state, {
                propertyDataById: action.propertyDataById,
            });
        case SET_ALL_PROPERTY_DATA:
            return Object.assign({}, state, {
                allPropertyData: action.allPropertyData,
                filteredAllPropertyData: action.filteredAllPropertyData,
            });
        case SET_FILTERED_ALL_PROPERTY_DATA:
            return Object.assign({}, state, {
                filteredAllPropertyData: action.filteredAllPropertyData,
            });
        case SET_SELECTED_TABLE_DATA:
           return Object.assign({}, state, {
            selectedTableData: action.selectedTableData
           });
           case SET_OLD_FILTERED_ALL_PROPERTY_DATA:
            return Object.assign({}, state, {
                oldFilteredPropertyData: action.oldFilteredPropertyData,
            });
        default:
            return state;
    }
}
