import { SET_SUMMARY_DATA, 
         SET_POSTCODE_FOR_AUTOCOMPLETE, 
         SET_FILTER_DATA_PROPERTY_TYPE, 
         SET_WITHDRAWAL_REPORT_DATA, 
         SET_WITHDRAWAL_REPORT_DATA_PO, 
         SET_WITHDRAWAL_REPORT_DATA_WITH_AGENCY, 
         SET_WITHDRAWAL_REPORT_DATA_WITH_AGENT, 
         SET_WITHDRAWAL_REPORT_DETAIL_DATA, 
         SET_FILTER_DATA_START_DATE, 
         SET_FILTER_DATA_IS_AGENT_WISE, 
         SET_DETAIL_REPORT_DATA, 
         SET_FILTER_DATA_END_DATE, 
         SET_FILTER_DATA_TYPE, 
         SET_FILTER_DATA_POST_CODE, 
         SET_FILTER_DATA_SUBURB, 
         SET_FILTER_DATA_AGENCY_NAME, 
         SET_FILTER_DATA_AGENT_NAME, 
         SET_FILTER_DATA_SUMMARY_TYPE, 
         SET_SUMMARY_DATA_WITH_AGENT, 
         SET_SUMMARY_DATA_WITH_AGENCY, 
         SET_SUBURB_LIST, SET_AGENCY_AGENT_LIST_FOR_REPORTS, 
         SET_AGENTS_FOR_AUTOCOMPLETE, 
         SET_FILTER_AGENCY_ARRAY, 
         SET_FILTER_AGENT_ARRAY, 
         SET_SUMMARY_DATA_PO_SUMMARY,
         SET_LEVEL_ZERO_DATA,
         SET_LEVEL_ONE_DATA,
         SET_LEVEL_TWO_DATA,
         SET_LEVEL_THREE_DATA,
         SET_LEVEL_FOUR_DATA
         } from '../actions/actionTypes';

export default function reportReducer(state = {
  summaryData: [],
  summaryDataWithPoSummary: [],
  summaryDataWithAgency: [],
  summaryDataWithAgent: [],
  detailReportData: [],
  withdrawalSummaryData: [],
  withdrawalSummaryPoData: [],
  withdrawalSummaryDataWithAgency: [],
  withdrawalSummaryDataWithAgent: [],
  withdrawalDetailReportData: [],

  levelZeroData: [],
  levelOneData: [],
  levelTwoData: [],
  levelThreeData: [],
  levelFourData: [],
  levelFiveData: [],

  //above to set data for tables

  summaryTypeForSelect: [{id: 1, name: 'Leaderboard', value: 'leader-bord'}, /* {id: 2, name: 'Marketshare', value: 'market-share'}, */ {id: 3, name: 'Average days on market', value: 'age-listing'}],
  postCodeForAutocomplete: [],
  suburbNameForAutocomplete: [],
  agencyAgentNameForAutocomplete: [],
  agentsForAutocomplete: [],
  dateForSelect: [{id: 1, name: 'Last Week'}, {id: 2, name: 'Last Month'}, {id: 3, name: 'Last Year'}, {id: 4, name: 'Custom Date'}],
  dateForSelectWithDrawal: [{id: 1, name: 'Last Week'}, {id: 2, name: 'Last Month'}, {id: 3, name: 'Custom Date'}],
  typeForSelect: [/* {id: 1, name: 'All', value: true}, */{id: 1, name: 'Rental', value: true}, {id: 2, name: 'Sale', value: false}],
  propertyTypeForSelect: ['All','House','Unit','Townhouse','Land'],
  //above to intialize data for select and autocomplete component
  // [{name: 'All'},{name: 'House'},{name: 'Unit'},{name: 'Commercial'},{name: 'Industrial'},{name: 'Vacant Land'},{name: 'Other'}],
  startDate: '',
  endDate: '',
  isRental: null,
  agencyName: [],
  agentName: [],
  postCodes: [],
  suburbName: [],
  summaryType: 'leader-bord',
  isAgentWise: false,
  propertyType: [],
  filterAgentName: [],
  filterAgencyName: [],
  //above to save filter data for api call
}, action) {
  switch (action.type) {
    case SET_SUMMARY_DATA: 
     return Object.assign({}, state, {
       summaryData: action.summaryData
     });
     case SET_FILTER_DATA_START_DATE: 
      return Object.assign({}, state, {
        startDate: action.startDate
     });
     case SET_FILTER_DATA_END_DATE:
       return Object.assign({}, state, {
         endDate: action.endDate
     });
     case SET_FILTER_DATA_TYPE:
       return Object.assign({}, state, {
         isRental: action.listingType
     })
     case SET_FILTER_DATA_POST_CODE: 
      return Object.assign({}, state, {
        postCodes: action.postCodes
      })
     case SET_FILTER_DATA_SUBURB:
       return Object.assign({}, state, {
        suburbName: action.suburbName 
      })
     case SET_FILTER_DATA_AGENCY_NAME:
       return Object.assign({}, state, {
         agencyName: action.agencyName
      })
     case SET_FILTER_DATA_AGENT_NAME:
       return Object.assign({}, state, {
         agentName: action.agentName
      })
     case SET_FILTER_DATA_SUMMARY_TYPE:
       return Object.assign({}, state, {
         summaryType: action.summaryType
       })
     case SET_SUMMARY_DATA_WITH_AGENCY:
       return Object.assign({}, state, {
        summaryDataWithAgency: action.summaryDataWithAgency
      })
     case SET_SUMMARY_DATA_WITH_AGENT:
       return Object.assign({}, state, {
         summaryDataWithAgent: action.summaryDataWithAgent
      })
     case SET_SUBURB_LIST:
       return Object.assign({}, state, {
        suburbNameForAutocomplete: action.suburbList
      })
     case SET_FILTER_DATA_PROPERTY_TYPE:
       return Object.assign({}, state, {
         propertyType : action.propertyType
      })
     case SET_FILTER_DATA_IS_AGENT_WISE:
       return Object.assign({}, state, {
        isRental: action.isRental
       })
     case SET_DETAIL_REPORT_DATA:
       return Object.assign({}, state, {
        detailReportData: action.detailReportData
       })
     case SET_WITHDRAWAL_REPORT_DATA: 
      return Object.assign({}, state, {
        withdrawalSummaryData: action.withdrawalReportData
      })
     case SET_WITHDRAWAL_REPORT_DATA_WITH_AGENCY:
      return Object.assign({}, state, {
        withdrawalSummaryDataWithAgency: action.withdrawalSummaryDataWithAgency
      })
     case SET_WITHDRAWAL_REPORT_DATA_WITH_AGENT:
      return Object.assign({}, state, {
        withdrawalSummaryDataWithAgent: action.withdrawalSummaryDataWithAgent
      })
     case SET_WITHDRAWAL_REPORT_DETAIL_DATA:
      return Object.assign({}, state, {
        withdrawalDetailReportData: action.withdrawalDetailData
      })
     case SET_POSTCODE_FOR_AUTOCOMPLETE:
       return Object.assign({}, state, {
        postCodeForAutocomplete: action.postCodeForAutocomplete
       })
     case SET_AGENCY_AGENT_LIST_FOR_REPORTS:
       return Object.assign({}, state, {
        agencyAgentNameForAutocomplete: action.agencyAgentNameForAutocomplete
       })
     case SET_AGENTS_FOR_AUTOCOMPLETE:
       return Object.assign({}, state, {
        agentsForAutocomplete: action.agentsForAutocomplete
       })
     case SET_FILTER_AGENCY_ARRAY:
       return Object.assign({}, state, {
        filterAgencyName : action.filterAgencyName
       })
     case SET_FILTER_AGENT_ARRAY:
       return Object.assign({}, state, {
         filterAgentName : action.filterAgentName
       })
     case SET_SUMMARY_DATA_PO_SUMMARY:
       return Object.assign({}, state, {
        summaryDataWithPoSummary : action.summaryDataWithPoSummary
       })
     case SET_WITHDRAWAL_REPORT_DATA_PO:
       return Object.assign({}, state, {
        withdrawalSummaryPoData : action.withdrawalSummaryPoData
       })
     case SET_LEVEL_ZERO_DATA:
       return Object.assign({}, state, {
         levelZeroData: action.levelZeroData
       })
     case SET_LEVEL_ONE_DATA:
       return Object.assign({}, state, {
         levelOneData: action.levelOneData
       })
     case SET_LEVEL_TWO_DATA:
       return Object.assign({}, state, {
         levelTwoData: action.levelTwoData
       })
     case SET_LEVEL_THREE_DATA:
       return Object.assign({}, state, {
         levelThreeData: action.levelThreeData
       })
     case SET_LEVEL_FOUR_DATA:
       return Object.assign({}, state, {
         levelFourData: action.levelFourData
       })
    default: return state;
  }
}
