import React, { lazy, Suspense, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from './actions/user-actions';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClipLoader } from 'react-spinners';

import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';

// Layout Blueprints
import { MinimalLayout } from './layout-blueprints';

import HomepageLayout from './layout-blueprints/HomepageLayout';

// Layout components
import SideHeader from './layout-components/SideHeader';

//example components
import Login from './components/Login';
import Register from './components/Register';

import TermsPage from './components/TermsPage';
import VerifyEmail from './components/VerifyEmail';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ToastComponent from './components/ToastComponent';
import ResendVerificationMail from './components/ResendVerificationMail';
import PrivacyStatement from './components/PrivacyStatementPage';
import PrivacyPolicy from 'components/PrivacyPolicy';

//lazy routes
const DashboardLayout = lazy(() =>
  import('./layout-blueprints/DashboardLayout')
);

const HowItWorks = lazy(() => import('./components/HowItWorks'));
const CompetitorLanding = lazy(() => import('./components/CompetitorLanding'));
const ProductInclusions = lazy(() => import('./components/ProductInclusions'));
const GettingStarted = lazy(() => import('./components/GettingStarted'));
const ContactUs = lazy(() => import('./components/ContactUs'));
const FAQs = lazy(() => import('./components/FAQs'));
const Homepage = lazy(() => import('./components/Homepage'));
const Subscriptions = lazy(() => import('./components/Subscriptions'));

const Routes = (props) => {
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      props.userActions.getUserDetails();
    }
  }, [props.userActions]);

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClipLoader color={'#3c44b1'} loading={true} />
                </div>
                {/* <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the page
                  <span className="font-size-lg d-block text-dark">
                    You will be redirected to the page in a while.
                  </span>
                </div> */}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <ToastComponent />
          <Switch>
            <Redirect exact from="/" to="/Home" />

            <Route
              path={[
                '/Home',
                '/HowItWorks',
                '/CompetitorLanding',
                '/ProductInclusions',
                '/GettingStarted',
                '/ContactUs',
                '/FAQs',
                '/Subscriptions/:filterType/:value/:isPrincipal',
                '/Subscriptions/:filterType/:value',
                '/Subscriptions',
                '/login',
                '/register',
                '/terms',
                '/verify-email/:verificationCode',
                '/forgot-password',
                '/reset-password/:verificationToken',
                '/resend-verification-mail/:email',
                '/privacy-statement',
                '/privacy-policy'
              ]}>
              <HomepageLayout>
                <SideHeader />
                <div className="p-3">
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}
                      className="p-3">
                      <Route path="/Home" component={Homepage} />
                      <Route path="/HowItWorks" component={HowItWorks} />
                      <Route
                        path="/CompetitorLanding"
                        component={CompetitorLanding}
                      />
                      <Route
                        path="/ProductInclusions"
                        component={ProductInclusions}
                      />
                      <Route
                        path="/GettingStarted"
                        component={GettingStarted}
                      />
                      <Route path="/ContactUs" component={ContactUs} />
                      <Route path="/FAQs" component={FAQs} />
                      <Route
                        exact
                        path="/Subscriptions/:filterType/:value/:isPrincipal"
                        component={Subscriptions}
                      />
                      <Route
                        exact
                        path="/Subscriptions/:filterType/:value"
                        component={Subscriptions}
                      />
                      <Route
                        exact
                        path="/Subscriptions"
                        component={Subscriptions}
                      />
                      <Route path="/login" component={Login} />
                      <Route path="/register" component={Register} />
                      <Route path="/terms" component={TermsPage} />
                      <Route
                        path="/verify-email/:verificationCode"
                        component={VerifyEmail}
                      />
                      <Route
                        path="/forgot-password"
                        component={ForgotPassword}
                      />
                      <Route
                        path="/reset-password/:verificationToken"
                        component={ResetPassword}
                      />
                      <Route
                        path="/resend-verification-mail/:email"
                        component={ResendVerificationMail}
                      />
                      <Route
                        path="/privacy-statement"
                        component={PrivacyStatement}
                      />
                      <Route path="/privacy-policy" component={PrivacyPolicy} />
                    </motion.div>
                  </Switch>
                </div>
              </HomepageLayout>
            </Route>

            <Route path={[]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}></motion.div>
                </Switch>
              </MinimalLayout>
            </Route>

            {localStorage.getItem('token') && (
              <Route
                path={[
                  '/map',
                  '/map-layout',
                  '/profile',
                  '/user-post-code-select',
                  '/configure-application-postcodes',
                  '/invite-agent',
                  '/plan-listing',
                  '/plans/:planId',
                  '/plans',
                  '/plan-purchase-payment/:planId',
                  '/plan-purchase-success-page/:planId',
                  '/change-password',
                  '/dashboard',
                  '/plan-history',
                  '/user-history',
                  '/check-price-finder/:planId',
                  '/contact-admin-price-finder',
                  '/contact-admin-payment',
                  '/assign-plan-to-user',
                  '/SubscriptionInactivePage',
                  '/admin-global-postcode-selection',
                  '/analysis-report',
                  '/analysis-leaderboard-report',
                  '/analysis-detail-report',
                  // '/withdrawn-report',
                  // '/withdrawn-detail-report',
                  '/topup/:planId',
                  '/topup-purchase/:planId/:isAgent',
                  '/postcode-request',
                  '/registered-users',
                  '/edit-user/:userId',
                  '/invite-principle-agent'
                ]}>
                <DashboardLayout
                  location={location}
                  pageVariants={pageVariants}
                  pageTransition={pageTransition}
                />
              </Route>
            )}

            <Redirect from="*" to="/" />
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetials: state.userReducer.userDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
