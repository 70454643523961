import React from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { IconButton } from '@material-ui/core';

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent
        })}>
        <div className="app-footer--first">
        </div>
        <div className="app-footer--second">
        </div>
        <div style={{alignItems: 'center', justifyContent: 'center', cursor:'pointer', animation:'fadeIn 0.3s', transition: 'opacity 0.4s', opacity: '1'}}>
       <IconButton className="m-2" color="primary" onClick={handleScrollToTop}>
         <div className='bg-primary text-white ml-3 rounded-circle d-30 btn-icon mx-auto'>
          <ArrowUpwardIcon />
         </div>
       </IconButton>
      </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);
