import { request } from '../utils/apiService';
import { SERVER_URL, ENDPOINT } from '../App.config';
import moment from 'moment';

export function register(email, password) {
  const requestBody = {
    email,
    password
  };
  return request(
    SERVER_URL + ENDPOINT + '/user/registration',
    'post',
    {
      'Content-Type': 'application/json'
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function login(email, password) {
  const requestBody = {
    email,
    password
  };
  return request(
    SERVER_URL + ENDPOINT + '/user/login',
    'post',
    {
      'Content-Type': 'application/json'
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function loginWithGoogle(tokenId, googleId) {
  const requestBody = {
    tokenId,
    googleId
  };
  return request(
    SERVER_URL + ENDPOINT + '/user/oauth/google',
    'post',
    {
      'Content-Type': 'application/json'
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function resendVerificationMail(email) {
  const requestBody = {
    email
  };
  return request(
    SERVER_URL + ENDPOINT + '/user/resend-verification',
    'post',
    {
      'Content-Type': 'application/json'
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function verifyEmail(verificationCode) {
  return request(
    SERVER_URL +
      ENDPOINT +
      '/user/email-verification?verificationEmail=' +
      verificationCode,
    'get',
    {
      'Content-Type': 'application/json'
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function forgotPassword(email) {
  const requestBody = {
    email
  };
  return request(
    SERVER_URL + ENDPOINT + '/user/forgot-password',
    'post',
    {
      'Content-Type': 'application/json'
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function resetPassword(password, verificatonToken) {
  const requestBody = {
    password,
    passwordVerificationToken: verificatonToken
  };
  return request(
    SERVER_URL + ENDPOINT + '/user/reset-password',
    'post',
    {
      'Content-Type': 'application/json'
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function userDetails() {
  return request(SERVER_URL + ENDPOINT + '/user', 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateProfile(profileObj) {
  return request(
    SERVER_URL + ENDPOINT + '/user/profile',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    profileObj
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getMapZoomLevel(preferenceObj) {
  return request(
    SERVER_URL + ENDPOINT + '/user/profile',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    preferenceObj
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllUsers() {
  return request(SERVER_URL + ENDPOINT + '/user/list', 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAreaPostcodes() {
  return request(SERVER_URL + ENDPOINT + '/suggest/postcode/list', 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addPostCodes(requestBody) {
  /* const requestBody = {
    postCodes
  }; */
  return request(
    SERVER_URL + ENDPOINT + '/principal-agent/add-postcode',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateAgentPostCodes(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/user/agent-postcodes',
    'put',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function agentInvite(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/principal-agent/agent-invite',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAgentList() {
  return request(SERVER_URL + ENDPOINT + '/principal-agent/agent-list', 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function deleteAgent(agentId) {
  const requestBody = {
    agentId
  };
  return request(
    SERVER_URL + ENDPOINT + '/principal-agent',
    'delete',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateAgentDetails(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/principal-agent/agent-update',
    'put',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function resendInvite(email, agentVerification) {
  const requestBody = {
    email,
    agentVerification
  };
  return request(
    SERVER_URL + ENDPOINT + '/principal-agent/agent-resend-invite',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllPlans() {
  return request(SERVER_URL + ENDPOINT + '/plans', 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllPlansForPlanList(pageSize, currentIndex) {
  return request(
    SERVER_URL +
      ENDPOINT +
      '/plans?pageSize=' +
      pageSize +
      '&currentIndex=' +
      currentIndex,
    'get',
    {
      'x-auth-token': localStorage.getItem('token')
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getPlanById(planId) {
  return request(SERVER_URL + ENDPOINT + '/plans/' + planId, 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addPlan(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/plans',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updatePlan(planId, requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/plans/' + planId,
    'put',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function deletePlanById(planId) {
  return request(SERVER_URL + ENDPOINT + '/plans/' + planId, 'delete', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function createPaymentIntentForPlanPurchase(planId) {
  return request(SERVER_URL + ENDPOINT + '/payment?planId=' + planId, 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function cardPayment(planId, stripePaymentId) {
  const requestBody = { planId, stripePaymentId };
  return request(
    SERVER_URL + ENDPOINT + '/payment',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function cardPaymentForTopup(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/payment/top-up-plan',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function stopCurrentTopup(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/payment/stop-subscription',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateOrderStatusForPlanPurchase(orderId, status) {
  const requestBody = {
    orderId,
    status
  };
  return request(
    SERVER_URL + ENDPOINT + '/payment/status',
    'put',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function changePassword(password, resetPassword) {
  const requestBody = {
    password,
    resetPassword
  };
  return request(
    SERVER_URL + ENDPOINT + '/user/change-password',
    'put',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getPlanHistory(
  currentIndex,
  pageSize,
  companyNames,
  queryString
) {
  const requestBody = {
    currentIndex,
    pageSize,
    companyNames,
    queryString
  };
  return request(
    SERVER_URL +
      ENDPOINT +
      '/report/plan-history' /* ?masterPrincipalAgent=+
      masterPrincipalAgent +
      '&currentIndex=' +
      currentIndex +
      '&pageSize=' +
      pageSize */,
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getUserActivityReport(
  companyNames,
  currentIndex,
  pageSize,
  queryString,
  filter
) {
  const requestBody = {
    companyNames,
    currentIndex,
    pageSize,
    queryString,
    filter
  };
  return request(
    SERVER_URL +
      ENDPOINT +
      '/report/user-activity' /* ?masterPrincipalAgent=' + masterPrincipalAgent + '&currentIndex=' + currentIndex + '&pageSize=' + pageSize, */,
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getSalesPropertyData(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/suburb/properties',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getSalesPropertyDataById(id) {
  return request(SERVER_URL + ENDPOINT + '/suburb/properties/' + id, 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getPropertyPrice(propertyDetails) {
  let marketStatus = propertyDetails?.marketStatus;
  // if(marketStatus && marketStatus.status === 'For Rent') {
  //   let rentalHistory = propertyDetails.rentalHistory?.listings[0];
  //   if(rentalHistory) {
  //       return rentalHistory.price.display;
  //   }
  // }
  // if(marketStatus && marketStatus.status === 'For Sale') {
  //     let listingHistory = propertyDetails.listingHistory?.listings[0];
  //     if(listingHistory) {
  //       return listingHistory.price.display;
  //     }
  // }
  if(marketStatus){
    // const listingHistory = propertyDetails?.listingHistory?.listings?.[0];
    // if(listingHistory){
      return propertyDetails?.price?.display;
    // }
  }
}

export function calculateDateDifference(startDate) {
  const endDate = moment(moment(new Date()).format("YYYY-MM-DD"), 'YYYY-MM-DD');
   startDate = moment(startDate, 'YYYY-MM-DD');
  return endDate.diff(startDate, 'days')
}

export function calculateDaysOnMarket(propertyData) {
  if(typeof propertyData.marketStatus === 'string') {
    let marketStatus = JSON.parse(propertyData?.marketStatus)?.status;
    const firstListedDate = JSON.parse(propertyData?.marketActivityListingDetails)?.firstListedDate;

    if(marketStatus){
      if(firstListedDate){
        return calculateDateDifference(firstListedDate);
      }
    }
  } else {
    let marketStatus = propertyData?.marketStatus?.status;
    if(marketStatus){
      if(propertyData?.marketActivityListingDetails?.firstListedDate){
        return calculateDateDifference(propertyData?.marketActivityListingDetails?.firstListedDate);
      }
    }
  }
}

export function getMapFilterData(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/suburb/filter',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function contactAdmin(isPriceFinderEnquiry, planId) {
  const requestBody1 = {
    isPriceFinderEnquiry,
    planId
  };
  const requestBody2 = {
    isPriceFinderEnquiry
  };
  return request(
    SERVER_URL + ENDPOINT + '/payment/contact-admin',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    isPriceFinderEnquiry ? requestBody2 : requestBody1
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getRentalPropertyData(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/suburb/rentals',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getRentalPropertyDataById(id) {
  return request(SERVER_URL + ENDPOINT + '/suburb/rentals/' + id, 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function AssignPlanstoUser(planId, userId) {
  const requestBody = {
    planId,
    userId
  };
  return request(
    SERVER_URL + ENDPOINT + '/plans/assing-plan',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getPaymentMethod() {
  return request(SERVER_URL + ENDPOINT + '/stripe/payment-method', 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function unsubscribeAfterCurrentPaymentCycle() {
  return request(
    SERVER_URL + ENDPOINT + '/stripe/payment-unsuscribe',
    'put',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    {}
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function unsubscribeImmediately() {
  return request(
    SERVER_URL + ENDPOINT + '/stripe/payment-unsuscribe-immediate',
    'put',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    {}
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyNames() {
  return request(SERVER_URL + ENDPOINT + '/report/comapny-names', 'get', {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCurrentSubscriptionDetails() {
  return request(
    SERVER_URL + ENDPOINT + '/stripe/current-subscription',
    'get',
    {
      'x-auth-token': localStorage.getItem('token')
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addPaymentMethod(stripeId) {
  const requestBody = {
    stripePaymentId: stripeId
  };
  return request(
    SERVER_URL + ENDPOINT + '/stripe/payment-method',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function makeDefaultPaymentMethod(stripeId) {
  const requestBody = {
    paymentMethod: stripeId
  };
  return request(
    SERVER_URL + ENDPOINT + '/stripe/make-default',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getStateListByCountry(params, countryCode = 'AU') {
  return request(
    SERVER_URL +
      ENDPOINT +
      '/basic/state-dropdown?countryCode=' +
      countryCode +
      (params && params.isApp != undefined ? '&isApp=' + params.isApp : '') +
      (params && params.isUse != undefined ? '&isUse=' + params.isUse : ''),
    'get',
    {
      'x-auth-token': localStorage.getItem('token')
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getPostcodesByStates(stateArray, params) {
  return request(
    SERVER_URL +
      ENDPOINT +
      '/basic/city-dropdown?stateCode=' +
      JSON.stringify(stateArray) +
      (params && params.isApp !== undefined ? '&isApp=' + params.isApp : '') +
      (params && params.isUse !== undefined ? '&isUse=' + params.isUse : ''),
    'get',
    {
      'x-auth-token': localStorage.getItem('token')
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addPostcodeFromGlobalPool(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/basic/add-postcodes',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function requestCustomPlan(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/plans/custom-plan',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getStripeSubscription() {
  return request(
    SERVER_URL + ENDPOINT + '/stripe/make-stripe-subscription',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function setGlobalPostcodeSelection(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/basic/set-app-city',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function setApplicationPostcodes(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/basic/add-data-city',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function cancelCustomPlan(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/stop-custom-subscription',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getSummaryReport(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/summary-report',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getSummaryReportWithPostcodes(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/summary-report/postcodes',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getSuburbslist(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/basic/suburb-dropdown',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getDetailReport(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/detail-report',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getWithdrawalReportData(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/summary-report/withdrawal',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getWithdrawalReportDataWithPostCodes(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/summary-report/withdrawal-postcodes',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getWithdrawalReportDetailData(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/detail-report/withdrawal',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAllPostcodeRequest(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/postcode-request',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updatePostcodeRequestStatus(requestId) {
  return request(
    SERVER_URL + ENDPOINT + '/postcode-request/' + requestId,
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    }
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAgentAgencyList(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/agency-agent/list',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getAgentAgencyListForWithdrawal(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/agency-agent/list-withdrawal',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getLevelZeroData(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/levelwise-report/postcodes-wise',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getLevelOneData(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/levelwise-report/suburbs-wise',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getLevelTwoData(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/levelwise-report/agency-wise',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getLevelThreeData(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/levelwise-report/agent-wise',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getLevelFourData(requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/report/levelwise-report/property-wise',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function uploadProfilePic(formData) {
  return request(
    SERVER_URL + ENDPOINT + '/user/profile-photo',
    'post',
    {
      'Content-Type': 'multipart/form-data',
      'x-auth-token': localStorage.getItem('token')
    },
    formData
  ).then((response) => {
    return response.data;
  })
  .catch((error) => {
    throw error;
  });
}

export function getUserList(currentIndex, pageSize,queryString) {
  const requestBody = {currentIndex, pageSize,queryString};
  return request(
    SERVER_URL + ENDPOINT + '/user/userList',
    'post',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  ).then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error;
    });
}

export function getUserById(userId) {
  return request(SERVER_URL + ENDPOINT + '/user/getUser/' + userId,
   'get',
    {
    'x-auth-token': localStorage.getItem('token')
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}


export function updateUserFromUserListing(userId, requestBody) {
  return request(
    SERVER_URL + ENDPOINT + '/user/editUser/' + userId,
    'put',
    {
      'x-auth-token': localStorage.getItem('token')
    },
    requestBody
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    })
  }

  export function invitePrincipalAgent(requestBody){
    return request(SERVER_URL + ENDPOINT + '/principal-agent/invite', "post", {
      'x-auth-token': localStorage.getItem('token'),
    }, requestBody)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    })
  }