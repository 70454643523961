import React from 'react';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import loaderStyles from './style';
import { connect } from 'react-redux';
import Axios from 'axios';

class LoaderComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          showLoader: 0,
        };
      }
    
      componentWillMount() {
        const component = this;
        Axios.interceptors.request.use(
          function (config) {
            component.setState({ showLoader: component.state.showLoader + 1 });
            return config;
          },
          function (error) {
            return Promise.reject(error);
          }
        );
    
        Axios.interceptors.response.use(
          function (response) {
            component.setState({ showLoader: component.state.showLoader - 1 });
            return response;
          },
          function (error) {
            component.setState({ showLoader: component.state.showLoader - 1 });
            return Promise.reject(error);
          }
        );
      }

    render(){
        const {classes} = this.props;
        return(
            <div>
                <Backdrop className={classes.backdrop} open={this.state.showLoader !== 0 || this.props.isLoaderOpen}>
                    <CircularProgress color="primary" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isLoaderOpen: state.loaderReducer.isLoaderOpen,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loaderStyles)(LoaderComponent));