import { SET_AGENT_LIST } from '../actions/actionTypes';
  
export default function inviteAgentReducer(state = {
    agentList: []
}, action) {
    switch (action.type) {
        case SET_AGENT_LIST:
            return Object.assign({}, state, {
                agentList: action.agentList,
            });
        default:
            return state;
    }
}