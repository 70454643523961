import { SET_CSV_DATA } from '../actions/actionTypes';

export default function exportDataReducer(
  state = {
    userActivity: []
    /* totalUserActivityElements: null */
  },
  action
) {
  switch (action.type) {
    case SET_CSV_DATA:
      return Object.assign({}, state, {
        userActivity: action.userActivity
        /* totalUserActivityElements: action.totalUserActivityElements */
      });

    default:
      return state;
  }
}
