import ThemeOptions from './ThemeOptions';
import toastReducer from './toast-reducer';
import userReducer from './user-reducer';
import areacodeReducer from './areacode-reducer';
import inviteAgentReducer from './invite-agent-reducer';
import plansReducer from './plans-reducer';
import masterdataReducer from './masterdata-reducer';
import loaderReducer from './loader-reducer';
import mapReducer from './map-reducer';
import adminReducer from './admin-reducer';
import exportDataReducer from './exportdata-reducer';
import reportReducer from './report-reducer';

export default {
  ThemeOptions,
  toastReducer,
  userReducer,
  areacodeReducer,
  inviteAgentReducer,
  plansReducer,
  masterdataReducer,
  loaderReducer,
  mapReducer,
  adminReducer,
  exportDataReducer,
  reportReducer
};
