import { SET_PLAN_LIST, SET_PLAN_BY_ID, SET_PAYMENT_INTENT_KEY, SET_PLAN_HISTORY, SET_PLAN_LIST_FOR_ADMIN, SET_PAYMENT_METHOD, SET_CURRENT_SUBSCRIPTION_DETAILS, SET_CURRENT_TOPUP_DETAILS } from '../actions/actionTypes';
  
export default function plansReducer(state = {
    planList: [],
    planById: null,
    paymentIntentKey: null,
    orderId: null,
    planHistory: [],
    totalPlanHistoryElements: null,
    paymentMethod: [],
    currentSubscriptionDetails: null,
    currentTopupDetails: null,
    planListForAdmin: {data:[]},
}, action) {
    switch (action.type) {
        case SET_PLAN_LIST:
            return Object.assign({}, state, {
                planList: action.planList,
            });
        case SET_PLAN_BY_ID:
            return Object.assign({}, state, {
                planById: action.planById,
            });
        case SET_PAYMENT_INTENT_KEY:
            return Object.assign({}, state, {
                paymentIntentKey: action.paymentIntentKey,
                orderId: action.orderId,
            });
        case SET_PLAN_HISTORY:
            return Object.assign({}, state, {
                planHistory: action.planHistory,
                totalPlanHistoryElements: action.totalPlanHistoryElements,
            });
        case SET_PAYMENT_METHOD:
            return Object.assign({}, state, {
                paymentMethod: action.paymentMethod,
            });
        case SET_CURRENT_SUBSCRIPTION_DETAILS:
            return Object.assign({}, state, {
                currentSubscriptionDetails: action.currentSubscriptionDetails,
            });
        case SET_CURRENT_TOPUP_DETAILS:
            return Object.assign({}, state, {
                currentTopupDetails: action.currentTopupDetails,
            });
        case SET_PLAN_LIST_FOR_ADMIN:
            return Object.assign({}, state, {
              planListForAdmin: action.planListForAdmin,
            });
        default:
            return state;
    }
}
