import React from 'react';
import { Grid, Button, Card, CardContent, Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authenticationActions from '../../actions/authentication-actions';

class ResendVerificationMail extends React.Component {
  constructor(props) {
    super(props);
    this.handleVerify = this.handleVerify.bind(this);
  }

  handleVerify() {
    this.props.match.params.email &&
      this.props.authenticationActions.resendVerificationMail(
        this.props.match.params.email
      );
  }

  render() {
    return (
      <>
        <Container className="py-3 py-xl-5">
          <Card className="modal-content">
            <CardContent className="p-0">
              <div className="text-center my-4">
                <h4 className="text-second display-3">Thank You!</h4>
              </div>
              <div className="text-center px-5">
                <p className="font-size-lg mb-2 ">
                  Thank you for registering with us. For security purpose and to
                  validate your email address, an email has been sent to the
                  email address provided during registeration.
                </p>
                <p className="font-size-lg mb-1">
                  If you have not received the email yet then please click the
                  button below to resend the verification email.
                </p>
              </div>
              <div className="text-center mb-5">
                <Button
                  onClick={this.handleVerify}
                  className="text-uppercase font-weight-bold font-size-sm mt-4 btn-primary">
                  Resend email to verify my email
                </Button>
              </div>
              <div className="text-center my-4">
                <p className="font-size-lg mb-1 text-black-50 px-5">
                  Please note that some email providers apply strict SPAM
                  filters by default, so if you have NOT received your
                  verification email within a few minutes, please also check
                  your SPAM folder.
                </p>
              </div>
            </CardContent>
          </Card>
        </Container>
        {/*<div className="app-wrapper bg-white min-vh-100">
          <div className="app-main min-vh-100">
            <div className="app-content p-0">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Grid item md={10} lg={8} xl={4} className="mx-auto">
                      <div className="text-center mb-5">
                         <h1 className="display-4 mb-1 font-weight-bold">
                                    {this.props.match.params.email}
                                  </h1> 
                        <p className="font-size-lg mb-0 text-black-50">
                          This email is not Verified
                        </p>
                        <p className="font-size-lg mb-0 text-black-50">
                          Please verify your email ID by clicking the link
                          recieved in an email on registering the system.
                        </p>
                      </div>

                      <div className="text-center mb-5">
                        <Button
                          fullWidth
                          onClick={this.handleVerify}
                          className="text-uppercase font-weight-bold font-size-sm mt-4 btn-primary">
                          Resend email to verify my email
                        </Button>
                      </div>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResendVerificationMail);
