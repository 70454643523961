import * as API from '../api/apiService';
import * as toastActions from './toast-actions';
import { history, store } from '../App';
import * as userActions from './user-actions';
import { ADMIN, DEFAULT_ERROR_MESSAGE, INDIVIDUAL_AGENT, PRINCIPAL_AGENT } from '../App.config';
import * as masterdataActions from './masterdata-actions';
import * as loaderActions from './loader-actions';
import { SET_RESET_STATE } from './actionTypes';

export const clearReduxData = () =>  ({type: SET_RESET_STATE});

export const register = (email, password) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.register(email, password)
            .then(result => {
                dispatch(toastActions.setToastSuccess(result.message));
                history.push('/resend-verification-mail/' + email)
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(toastActions.setToastFail(error.response.data.message[0]));
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export const login = (email, password) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.login(email, password)
            .then(result => {
                dispatch(toastActions.setToastSuccess("Logging In"));
                localStorage.setItem('token', result.token)

                const redirectPath = store.getState().masterdataReducer.loginRedirectPath;
                if(redirectPath){
                    history.push(redirectPath);
                    dispatch(masterdataActions.setLoginRedirect(null));
                    dispatch(loaderActions.setLoaderDisplay(false));
                    return;
                }

                history.push('/profile');
                dispatch(userActions.getUserDetails((userDetails)=>{
                const planId = store.getState().masterdataReducer.selectedPlanBeforeLogin;
                const isCustomTab = store.getState().masterdataReducer.isCustomTabSelect;
                    if(planId != null || isCustomTab){
                        history.push('/Subscriptions');
                    }else if(userDetails.companyName){
                        if(userDetails.masterPrincipalDetails && userDetails.masterPrincipalDetails.status==="active" && !userDetails.roles.includes(ADMIN)){
                            if(userDetails.masterPrincipalDetails.postcodes.length===0){
                                history.push('/user-post-code-select');
                                dispatch(toastActions.setToastInfo("Let's start by selecting postcodes"))
                            }
                            else{
                                history.push('/map');
                            }
                        }
                        else if(userDetails.roles && userDetails.roles.length>0 && userDetails.roles.includes(ADMIN)){
                            history.push('/dashboard');
                        }
                        else{
                            history.push('/Subscriptions');
                        }
                    }
                }))
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                if(error.response.data.message && error.response.data.message[0]==="Pls. verify email to login"){
                    history.push('/resend-verification-mail/' + email);
                }
                else{
                    dispatch(toastActions.setToastFail(error.response.data.message[0] ? error.response.data.message[0] : DEFAULT_ERROR_MESSAGE));
                }
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export const loginWithGoogle = (tokenId, googleId) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.loginWithGoogle(tokenId, googleId)
            .then(result => {
                dispatch(toastActions.setToastSuccess("Logging In"));
                localStorage.setItem('token', result.token)

                const redirectPath = store.getState().masterdataReducer.loginRedirectPath;
                if(redirectPath){
                    history.push(redirectPath);
                    dispatch(masterdataActions.setLoginRedirect(null));
                    dispatch(loaderActions.setLoaderDisplay(false));
                    return;
                }

                history.push('/profile')
                dispatch(userActions.getUserDetails((userDetails)=>{
                    if(userDetails.companyName){
                        if(userDetails.masterPrincipalDetails && userDetails.masterPrincipalDetails.status==="active"){
                            if(userDetails.masterPrincipalDetails.postcodes.length===0){
                                history.push('/user-post-code-select');
                                dispatch(toastActions.setToastInfo("Let's start by selecting postcodes"))
                            }
                            else{
                                history.push('/map');
                            }
                        }
                        else if(userDetails.roles && userDetails.roles.length>0 && userDetails.roles.includes(ADMIN)){
                        }
                        else{
                            history.push('/Subscriptions');
                        }
                    }
                }))
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(toastActions.setToastFail(error.response.data.message[0]));
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export const resendVerificationMail = (email) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.resendVerificationMail(email)
            .then(result => {
                dispatch(toastActions.setToastSuccess(result.message));
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(toastActions.setToastFail(error.response.data.message[0]));
                history.push('/login')
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export const verifyEmail = (verificationCode) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.verifyEmail(verificationCode)
            .then(result => {
                dispatch(toastActions.setToastSuccess(result.message));
                history.push('/login')
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(toastActions.setToastFail(error.response.data.message[0]));
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export const forgotPassword = (email) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.forgotPassword(email)
            .then(result => {
                dispatch(toastActions.setToastSuccess(result.message));
                history.push('/login')
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(toastActions.setToastFail(error.response.data.message));
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export const resetPassword = (password, verificatonToken) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.resetPassword(password, verificatonToken)
            .then(result => {
                dispatch(toastActions.setToastSuccess(result.message));
                history.push('/login')
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(toastActions.setToastFail(error.response.data.message));
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export const changePassword = (oldPassword, newPassword) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.changePassword(oldPassword, newPassword)
            .then(result => {
                dispatch(toastActions.setToastSuccess(result.message));
                dispatch(logout('/login'))
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(toastActions.setToastFail(error.response.data.message));
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export const logout = (redirectPath) => {
    localStorage.removeItem('token');
    localStorage.removeItem('areMapTermsAgreed')
    localStorage.removeItem('mapType')
    localStorage.removeItem('mapCenter')
    localStorage.removeItem('mapZoom')
    localStorage.removeItem('selectedMapArea')
    redirectPath && history.push(redirectPath)
    return (dispatch) => {
        dispatch(clearReduxData());
    }
}
