import { SET_POSTCODE_LIST, SET_REQUEST_POSTCODE_LIST } from '../actions/actionTypes';
  
export default function areacodeReducer(state = {
    postCodes: null,
    requestedPostCodes: [],
}, action) {
    switch (action.type) {
        case SET_POSTCODE_LIST:
            return Object.assign({}, state, {
                postCodes: action.postCodes,
            });
        case SET_REQUEST_POSTCODE_LIST: 
            return Object.assign({}, state, {
              requestedPostCodes: action.requestedPostCodes
            })
        default:
            return state;
    }
}
