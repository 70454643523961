import React from 'react';
import { Grid, InputAdornment, Button, TextField } from '@material-ui/core';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import { EMAIL_PATTERN } from '../../App.config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authenticationActions from '../../actions/authentication-actions';

class ForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.state={
            inputFields: {
                email: "",
            },
            validationErrors: {
                email: "",
            },
            isSubmitClicked: false,
            isAllValid: false,
        };
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
    }

    componentDidMount(){
        window.addEventListener('keydown', (e) => {
            if (e.keyCode === 13) {
                e.target.removeEventListener('keydown', () => {})
                this.handleSubmitClick(e);
            }
        });
    }

    handleInputChange(event){
        let name = event.target.name;
        let value = event.target.value.trim();
        let inputObject = this.state.inputFields;
        inputObject[name] = value;
        this.setState({ inputFields: inputObject }, ()  => {
            this.state.isSubmitClicked && this.validateFields();
        });
    }

    checkErrors(callbackFunction){
        let doErrorExist = false;
        Object.values(this.state.validationErrors).map(error => {
            if(error){
                doErrorExist = true;
            }
            return null;
        });
        this.setState({ isAllValid: !doErrorExist }, () => {
            callbackFunction && callbackFunction();
        });
    }

    handleSubmitClick(event){
        event && event.preventDefault();
        this.setState({ isSubmitClicked: true });
        this.validateFields(() => {
            if(this.state.isAllValid){
              this.props.authenticationActions.forgotPassword(this.state.inputFields.email);
            }
        });
    }

    validateFields(callbackFunction){
        let inputObject = this.state.inputFields;
        let inputErrorObject = this.state.validationErrors;
        Object.keys(inputObject).map(fieldName => {
            if(!inputObject[fieldName]){
                inputErrorObject[fieldName] = "Field cannot be empty";
                return null;
            }
            if(fieldName==="email" && !EMAIL_PATTERN.test(inputObject[fieldName])){
                inputErrorObject[fieldName] = "Invalid eamil";
            }
            else{
                inputErrorObject[fieldName] = "";
            }
            return null;
        });
        this.setState({ validationErrors: inputErrorObject }, () => {
            this.checkErrors(callbackFunction && callbackFunction);
        });
    }


    render(){
        const {classes} = this.props;
        return(
          <>
            <div className="app-wrapper bg-white min-vh-100">
              <div className="app-main min-vh-100">
                <div className="app-content p-0">
                  <div className="app-content--inner d-flex align-items-center">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div className="bg-composed-wrapper--content py-5">
                        <Grid item md={10} lg={8} xl={4} className="mx-auto">
                          <div className="text-center mb-5">
                            <h1 className="display-4 mb-1 font-weight-bold">
                              Recover Password
                            </h1>
                            <p className="font-size-lg mb-0 text-black-50">
                              Forgot your password? No worries, we're here to help!
                            </p>
                          </div>
                          <div>
                            <label className="font-weight-bold mb-2">
                              Email address
                            </label>
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="textfield-email"
                              name="email" 
                              value={this.state.inputFields.email}
                              onChange={this.handleInputChange}
                              helperText={this.state.validationErrors.email} 
                              error={this.state.validationErrors.email ? true : false}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineTwoToneIcon />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-between align-items-center font-size-md  mt-2">
                            <div></div>
                            <div>
                              <a
                                href="#/"
                                onClick={(e) => {e.preventDefault(); this.props.history.push('/login')}}
                                className="text-first">
                                Sign In
                              </a>
                            </div>
                          </div>
                          <div className="text-center mb-5">
                            <Button
                              fullWidth
                              onClick={this.handleSubmitClick}
                              className="text-uppercase font-weight-bold font-size-sm mt-4 btn-primary">
                              Recover password
                            </Button>
                          </div>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
    }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);