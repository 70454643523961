import { SET_LOGIN_REDIRECT_PATH, SET_STATE_LIST_BY_COUNTRY, SET_POSTCODE_LIST_BY_STATE, SET_SELECTED_PLAN_BEFORE_LOGIN, SET_ISCUSTOME_TAB_SELECT } from './actionTypes';
import * as toastActions from './toast-actions';
import * as loaderActions from './loader-actions';
import { DEFAULT_ERROR_MESSAGE } from 'App.config';
import * as API from '../api/apiService';
import {store} from '../App';

export function setLoginRedirect(loginRedirectPath){
    return{
        type: SET_LOGIN_REDIRECT_PATH,
        loginRedirectPath: loginRedirectPath,
    }
}

export function setStateListByCountry(stateListByCountry){
    return{
        type: SET_STATE_LIST_BY_COUNTRY,
        stateListByCountry: stateListByCountry,
    }
}

export function setSelectedPlanBeforeLogin(selectedPlanBeforeLogin) {
    return {
      type: SET_SELECTED_PLAN_BEFORE_LOGIN,
      selectedPlanBeforeLogin:selectedPlanBeforeLogin
    }
  }
  
  export function setIsCustomTab(isCustomTabSelect) {
    return {
      type: SET_ISCUSTOME_TAB_SELECT,
      isCustomTabSelect:isCustomTabSelect
    }
  }

export const getStateListByCountry = (params) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.getStateListByCountry(params && params)
            .then(result => {
                dispatch(setStateListByCountry(result.data));
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(toastActions.setToastFail(error.response && error.response.data && error.response.data.message[0] ? error.response.data.message[0] : DEFAULT_ERROR_MESSAGE));
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export function setPostcodesByStates(postcodeListByStates){
    return{
        type: SET_POSTCODE_LIST_BY_STATE,
        postcodeListByStates: postcodeListByStates,
    }
}

export function setPostCodes(postCodeList){
    return {
        type : 'NEW_POSTCODES_SELECTION',
        payload: postCodeList
    }
}

export function filterPostCodes(filterValue) {
    return {
        type : 'FILTER_POST_CODES',
        payload: filterValue
    }
}

export function clearFilteredPostCodes(){
    return {
        type : "CLEAR_FILTER_POSTCODES"
    }
}

export const getPostcodesByStates = (stateArray, params) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.getPostcodesByStates(stateArray, params && params)
            .then(result => {
                // dispatch(setPostcodesByStates(result.data.map(obj =>{
                //     obj.postcode = obj._id.toString()
                //     return obj
                // })));   

                const userDetails = store.getState()?.userReducer?.userDetails;
                const usersPostCodes  = userDetails?.masterPrincipalDetails?.postcodes;
                const  newData = result?.data?.map((postCode) => {
                    postCode.postcode = postCode._id.toString()
                    if(usersPostCodes?.includes(postCode?.postcode)){
                        postCode.isSelected = true
                    }else{
                        postCode.isSelected = false
                    }
                    return postCode
                })
                dispatch(setPostCodes(newData));
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export const addGlobalPostcodeSelection = (requestBody, successCallback) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.setGlobalPostcodeSelection(requestBody)
            .then(result => {
                successCallback && successCallback();
                dispatch(toastActions.setToastSuccess(result.message));
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(toastActions.setToastFail(error.response && error.response.data && error.response.data.message[0] ? error.response.data.message[0] : DEFAULT_ERROR_MESSAGE));
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}

export const addApplicationPostcodes = (requestBody, successCallback) => {
    return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
        API.setApplicationPostcodes(requestBody)
            .then(result => {
                successCallback && successCallback();
                dispatch(toastActions.setToastSuccess(result.message));
                dispatch(loaderActions.setLoaderDisplay(false));
            }, error => {
                dispatch(toastActions.setToastFail(error.response && error.response.data && error.response.data.message[0] ? error.response.data.message[0] : DEFAULT_ERROR_MESSAGE));
                dispatch(loaderActions.setLoaderDisplay(false));
            })
    }
}
