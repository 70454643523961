import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/user-actions';


function TermsPage(props) {

  const onTermsAgree = () => {
    props.userActions.agreeTerms(true);
  }
  

  return (
    <>
      <h5>
        WILSON AI (ACN: 628 446 444) ONLINE SUBSCRIPTION AGREEMENT TERMS &
        CONDITIONS
      </h5>

      <p>
        These terms apply to the provision of web-based property data and
        information services (<strong>Services</strong>) to any person or entity
        (<strong>Customer</strong>). By accessing this Wilson AI application (
        <strong>Wilson AI Application</strong>) you agree to be bound by these
        terms and conditions which together with the application form comprise
        your agreement with Wilson AI in respect of the Services (
        <strong>Agreement</strong>).
      </p>

      <p>
        Use of this Wilson AI Application by a third party entity or person will
        incur a fee to be paid to Wilson AI. Such fee will be agreed between the
        third party entity or person from time to time and must be paid in
        advance of using this Wilson AI application.
      </p>

      <p>
        This Wilson AI Application uses data sourced from multiple locations,
        including but not limited to Australian Property Monitors Pty Ltd ABN 42
        061 438 006 and its related bodies corporate (together{' '}
        <strong>Pricefinder</strong>).
      </p>

      <p style={{wordBreak: 'break-all'}}>
        As a condition of entering this agreement, your use of the Services are
        also governed by our (<strong>Conditions of Use</strong>){' '}
        <span className="text-info">
          http://www.domain.com.au/group/conditions-of-use/
        </span>
        , (<strong>Privacy Policy</strong>){' '}
        <span className="text-info">
          http://www.domain.com.au/group/privacy-policy/
        </span>{' '}
        and (<strong>Tracking & Targeting</strong>){' '}
        <span className="text-info">
          http://www.domain.com.au/group/tracking-and-targeting/
        </span>
        .
      </p>

      <p>
        Your access to the Wilson AI Application will commence when we notify
        you that your account has been provisioned (which will typically be
        within 5-10 business days after the date of this agreement) and will
        continue until it is cancelled in accordance with these terms and
        conditions.
      </p>

      <p>
        You may cancel the Services by providing us with no less than 30 days
        written notice, provided that such cancellation must not take effect
        before the expiry of any minimum contract term agreed with Wilson AI.
      </p>

      <p>
        We reserve the right to withdraw or change the Wilson AI Application/the
        Services (including content, format, design, scope, etc) at any time. We
        also reserve the right to change these terms and conditions. We will
        give you advance notice of any material changes that we make to these
        terms and conditions.
      </p>

      <p>
        You acknowledge that where you request information about a property in a
        particular State, you have read and accept the acknowledgements and
        disclaimers relevant to that State (which we are contractually required
        by our licensors and suppliers to have you agree to) (
        <strong>Wilson AI End User Terms of Use</strong>), and that these
        acknowledgements and disclaimers form part of these terms and
        conditions. conditions.
      </p>

      <p>You agree that:</p>
      <ol>
        <li>
          it is a fundamental term and condition of this Agreement that you
          accept and comply at all times with the Wilson AI End User Terms of
          Use;
        </li>
        <li>
          all data and information to which you have access in accordance with
          this Agreement is for your exclusive use as part of your marketing and
          other internal business requirements and you will not use it for any
          other purpose;
        </li>
        <li>
          you must not offer the data or information to which you have access in
          accordance with this Agreement, or combine the data or information
          with any other data or information and offer it, to third parties
          without our prior written consent (which may be given or withheld in
          our discretion);
        </li>
        <li>
          you must not request information for third parties nor permit requests
          to be made under this Agreement by others;
        </li>
        <li>
          you will keep your username and password for the Wilson AI Application
          safe and confidential, and you will be responsible for all activities
          via your username and password;
        </li>
        <li>
          you must not use the Wilson AI Application in any manner or for any
          purpose which is unlawful or in any manner which violates any rights
          of Wilson AI, its licensors and/or suppliers.
        </li>
        <li>
          you must not use any material or information provided pursuant to this
          Agreement on any other internet site or to establish, maintain or
          provide your own publications or promotional material;
        </li>
        <li>
          you must not use any material or information provided pursuant to this
          Agreement for the purposes of any email or other direct marketing
          campaign;
        </li>
        <li>
          you will comply with all applicable laws and regulations in relation
          to this Agreement; and
        </li>
        <li>
          you will bear the cost of any telecommunications and internet usage
          charges incurred as a result of using the Wilson AI or submitting
          property listings.
        </li>
      </ol>

      <p>
        We will also comply with all applicable laws and regulations in relation
        to this agreement. Unless otherwise agreed by us, fees are payable
        monthly within 30 days of the statement date on the invoice for all
        accounts.
      </p>

      <p>
        Without limiting our other rights and remedies at law, and
        notwithstanding anything to the contrary in these terms and conditions,
        in the event of non-payment or late payment, we may (at our discretion):
      </p>
      <ol>
        <li>
          suspend your access to the Wilson AI Application upon written notice
          to you;
        </li>
        <li>
          terminate this Agreement if the monies remain outstanding (in full or
          in part) after 7 days written notice requiring payment;
        </li>
        <li>
          charge you for our out of pocket legal fees associated with collection
          of overdue amounts provided we have given you notice for payment and
          any date specified in the notice for payment has passed; and/or
        </li>
        <li>
          charge interest on the overdue amount at a rate of 2% above the
          Westpac Overdraft Business Rate in respect of the period from the due
          date for payment until the date the outstanding amount is actually
          paid.
        </li>
      </ol>

      <p>
        The intellectual property rights (including copyright) in all data,
        information, text, material, graphics, software, source code and
        advertisements on the Wilson AI Application are owned and/or controlled
        by Wilson AI, its suppliers and/or licensors unless expressly indicated
        otherwise by Wilson AI. The Content is protected by Australian and
        international copyright and trademark laws. You must not modify, copy,
        reproduce, republish, frame, upload to a third party, post, transmit or
        distribute the Content in any way except as expressly provided for in
        these terms or expressly authorised in writing by Wilson AI. You must
        not claim any ownership of intellectual property rights in respect of
        the Content nor commit or permit any act of omission or commission by
        your agents, employees or any third party which will impair the
        copyright or other proprietary rights to the Content. Nothing in this
        Agreement should be construed as granting any licence or right to use
        any Content or trademark displayed on the Wilson AI Application without
        the express written consent of Wilson AI or the relevant owner.
      </p>

      <p>
        You are liable for all taxes (inc. GST), duties or government charges
        payable in connection with the execution of this Agreement whether
        applying at the date of this Agreement or in the future.
      </p>

      <p>
        Without limiting our other rights and remedies at law, a party may
        terminate this Agreement immediately:
      </p>

      <ol>
        <li>
          at any time and for any reason by giving the other party 30 days
          notice in writing (provided this notice is given after any applicable
          minimum term you have agreed to);
        </li>
        <li>
          if the other party breaches this Agreement and fails to rectify that
          breach within 14 days notice;
        </li>
        <li>
          if the other party becomes bankrupt, insolvent, enters into
          liquidation, administration or receivership, or a receiver or manager
          is appointed over any or all of that party’s assets; or
        </li>
        <li>
          if the other party dies, its partnership is dissolved, or it is
          deregistered (as applicable).
        </li>
      </ol>

      <p>
        In addition, Wilson AI may suspend or terminate this Agreement in whole
        or in part upon notice to you if the continuance of this Agreement will
        or may cause Wilson AI to be in breach of any licence or supply
        agreement it has entered into to facilitate it providing you with the
        Services.
      </p>

      <p>
        To the extent permitted by law, we exclude all conditions and warranties
        relating to the Services. You acknowledge that we are reliant on third
        parties for data and information that is provided on the Wilson AI
        Application and that such data and information enables us to provide the
        Services. Without limiting the generality of the foregoing, we do not
        make any representations or warranties that the Wilson AI Application
        will be uninterrupted or error free or that the Content on the Wilson AI
        Application will be accurate, complete and/or up-to-date (including
        without limitation any Content created through the use of algorithms).
        To the extent that our liability for breach of any implied warranty or
        condition cannot be excluded by law our liability will be limited in the
        manner detailed in the following paragraph.
      </p>

      <p>
        In no circumstances will a party be liable to the other party for any
        indirect, incidental, special and/or consequential losses or damages
        (including loss of profits, goodwill, data and/or opportunity). Our
        liability to you for the provision of the Services will be limited to
        re-supplying the relevant Services to you or refunding you a prorate
        amount of the subscription fees paid by you for the relevant Service (at
        our option). Your liability to us will be limited to the fees payable by
        you to us for the supply of the Services over a 12 month period.
      </p>

      <p>
        You may not assign this Agreement without our prior written consent.
        This Agreement, together with any terms and conditions it refers to,
        comprises the entire agreement between you and us in respect of the
        Services and supersedes all prior understandings, agreements or
        representations. No delay or waiver by us in enforcing any provision of
        this Agreement will be deemed a waiver of our rights.
      </p>

      <p>
        If a term of this Agreement is or becomes invalid or unenforceable, the
        validity and enforceability of the remainder of the Agreement will not
        be affected.
      </p>

      <p>
        These terms and conditions are governed by the laws in force in Victoria
        and you submit to the non-exclusive jurisdiction of the courts in that
        State.
      </p>

      <h5>WILSON AI END USER TERMS OF USE</h5>

      <p>
        You acknowledge that where you request information about a property in a
        particular State, you have read and accept the acknowledgements and
        disclaimers relevant to that State (which we are contractually required
        by our licensors and suppliers to have you agree to) (
        <strong>Wilson AI End User Terms of Use</strong>), and that these
        acknowledgements and disclaimers form part of these terms and
        conditions.
      </p>

      <p>
        <strong>Disclaimer</strong>
      </p>

      <p>
        Published and compiled by Wilson AI Pty Ltd <br />
        In compiling this publication, the Publisher relies upon information
        supplied by a number of external sources. The publication is supplied on
        the basis that while the Publisher believes all the information in it
        will be correct at the time of publication, it does not warrant its
        accuracy or completeness and to the full extent allowed by law excludes
        liability in contract, tort or otherwise, for any loss or damage
        sustained by subscribers, or by any other person or body corporate
        arising from or in connection with the supply or use of the whole or any
        part of the information in this publication through any cause whatsoever
        and limits any liability it may have to the amount paid to the Publisher
        for the supply of such information.
      </p>

      <p>
        <strong>New South Wales Land and Property Management Authority</strong>
      </p>

      <p>
        Contains property sales information provided under licence from the
        Department of, Land and Property Management Authority.
      </p>

      {/* <p>You are prohibited from:</p>

      <ol>
        <li>
          using the Property Sales Information other than for your own internal
          business or personal purpose;
        </li>
        <li>
          on-selling, sub-licensing, disclosing or otherwise providing Property
          Sales Information in any form to any other person;
        </li>
        <li>
          altering the format, meaning or substance of any Property Sales
          Information supplied;
        </li>
        <li>
          removing any notice displaying -Contains property sales information
          provided under licence from the Land and Property Information (LPI)-
          from any document you produce from this website.
        </li>
      </ol> */}

      {/* <p>You agree that you will:</p>

      <ol>
        <li>
          If providing the Property Sales Information to third parties, to
          comply with Privacy Legislation and to notify Property Data Solutions
          and Land and Property Information (LPI) of any privacy complaints made
          in connection with the Property Sales Information;
        </li>
        <li>
          act in accordance with the reasonable directions of Land and Property
          Information (LPI) in relation to any privacy complaint;
        </li>
      </ol>

      <p>
        I accept that use of Licensed Data Products by me is limited to my own
        personal use or for use in the ordinary course of my business. I will
        not on-sell or distribute Licensed Data Products for reward or otherwise
        to any other third party, nor will I produce any products incorporating
        this data for commercial use or otherwise except for those Hardcopy
        Products I am allowed to give away free of any charges. The Hardcopy
        Products are to be distributed at no charge and cannot be amalgamated
        into or combined with other products and services for which a charge is
        levied or a fee paid.
      </p> */}

      <p>
        <strong>State of Victoria</strong>
      </p>

      <p>
        The State of Victoria owns the copyright in the Property Sales Data and
        reproduction of that data in any way without the consent of the State of
        Victoria will constitute a breach of the Copyright Act 1968 (Cth). The
        State of Victoria does not warrant the accuracy or completeness of the
        Property Sales Data and any person using or relying upon such
        information does so on the basis that the State of Victoria accepts no
        responsibility or liability whatsoever for any errors, faults, defects
        or omissions in the information supplied.
      </p>

      <p>
        Privacy =. The information may not be used in the preparation of mailing
        lists or for direct marketing. Any contravention of this notice will
        result in immediate revocation of access to the information.
      </p>

      <p>
        <strong>State of Queensland</strong>
      </p>

      <p>Standard Terms</p>

      <p>All Licensed Data must bear the following notice:</p>
      <p style={{wordBreak: 'break-all'}}>
        &#169; State of Queensland (Department of Natural Resources, Mines and
        Energy) 2020. In consideration of the State permitting use of this data
        you acknowledge and agree that the State gives no warranty in relation
        to the data (including accuracy, reliability, completeness, currency or
        suitability) and accepts no liability (including without limitation,
        liability in negligence) for any loss, damage or costs (including
        consequential damage) relating to any use of the data. Data must not be
        used for direct marketing or be used in breach of the privacy laws; more
        information at http://www.propertydatacodeofconduct.com.au/
      </p>
      <p style={{wordBreak: 'break-all'}}>
        All Licensed Data Products (with the exception of those sent by SMS)
        must bear the following notice:Based on or contains data provided by the
        State of Queensland (Department of Natural Resources, Mines and Energy)
        2020. In consideration of the State permitting use of this data you
        acknowledge and agree that the State gives no warranty in relation to
        the data (including accuracy, reliability, completeness, currency or
        suitability) and accepts no liability (including without limitation,
        liability in negligence) for any loss, damage or costs (including
        consequential damage) relating to any use of the data. Data must not be
        used for direct marketing or be used in breach of the privacy laws; more
        information at http://www.propertydatacodeofconduct.com.au/
      </p>

      <p>End User Terms</p>

      <ul>
        <li>
          <strong>Direct Marketing</strong> means one to one marketing using
          personal details (e.g. name, address, email address or other personal
          information), normally supported by a database/resource, which uses
          one or more advertising media to effect a measurable response and/or
          transaction from a person (including a corporation or organisation)
          and includes, but is not limited to, telemarketing, bulk email
          messaging (spam), postal canvassing, and list brokering.
        </li>
        <li>
          <strong>Licensed Data</strong> means data that is owned by or licensed
          to the State of Queensland (Department of Natural Resources, Mines and
          Energy) and has been licensed to Australian Property Monitors under an
          agreement.
        </li>
        <li>
          <strong>Licensed Data Product(s)</strong> means any Value Added
          product derived from or based on the Licensed Data or any other
          Licensed Data Products(s).
        </li>
        <li>
          <strong>Mail Merge Functionality</strong> means a facility under which
          a form letter can be sent to many recipients with each letter
          personalised using a Licensed Data Product. The facility takes each
          recipient’s name and/or address (from a Licensed Data Product) and
          enters it in its usual place on a form letter, and may also print out
          mailing labels.
        </li>
        <li style={{wordBreak: 'break-all'}}>
          <strong>PIIPD Code of Conduct</strong> is the Personal Identification
          Information in Property Data Code of Conduct for access to bulk data
          including identified information in the Queensland Valuation and Sales
          System (QVAS) database. More information about the PIIPD Code of
          Conduct can be found at http://www.propertydatacodeofconduct.com.au/
        </li>
        <li>
          <strong>Privacy Laws</strong> means any legislation (or mandatory
          government policy, where applicable) enacted by Federal or State
          agencies in relation to privacy and includes the Privacy Act 1988(Cth)
          and Information Privacy Act 2009 (Qld).
        </li>
        <li>
          <strong>Value Add/Adding/Added</strong> means any repackaging which
          irreversibly changes the form of the Licensed Data or any augmenting
          or incorporation of the Licensed Data with other data. Conversion onto
          a different media or the translation into a different format (e.g.
          changing colour and formatting) of Licensed Data is not Value Adding.
        </li>
      </ul>

      <p>
        <strong>Ownership</strong> <br />I acknowledge that I have no rights of
        ownership in the Licensed Data. The State of Queensland (Department of
        Natural Resources, Mines and Energy) is the owner of the intellectual
        property rights including copyright in and to the Licensed Data or has
        the right to make it available under licence arrangements, and has made
        a licence arrangement with Australian Property Monitors.
      </p>

      <p>
        <strong>Liability</strong> <br />I acknowledge that the State of
        Queensland (Department of Natural Resources, Mines and Energy) gives no
        warranty in relation to the data (including accuracy, reliability,
        completeness, currency or suitability) and accepts no liability
        (including without limitation, liability in negligence) for any loss,
        damage or costs (including consequential damage) relating to any use of
        the data.
      </p>

      <p>
        <strong>Permitted Use</strong>I agree that I will not use the Licensed
        Data Product(s) to provide a Mail Merge Functionality, or with the
        intention of encroaching upon the privacy of an individual or for Direct
        Marketing and I will comply with the Privacy Laws and the PIIPD Code of
        Conduct as applicable.
      </p>

      <p>
        <strong>Permitted Use Terms – Licensee’s Customers</strong>I agree to
        use the Licensed Data Products that I receive from Australian Property
        Monitors and/or any customer of Australian Property Monitors only for my
        own personal use or in the ordinary course of my business (e.g.
        solicitor, accountant, valuer etc.). I am not a business acting as a
        reseller of Licensed Data Products.
      </p>

      {/* <p>
        Direct Marketing means one to one marketing, normally supported by a
        database, which uses one or more advertising media to effect a
        measurable response and/or transaction from a person (including a
        corporation or organisation) and includes, but is not limited to,
        telemarketing, bulk email messaging (spam), postal canvassing and list
        brokering
        <br />
        Licensed Data means data that is owned by or licensed to the State of
        Queensland (Department of Natural Resources and Mines) and licensed to
        others under agreements.
        <br />
        Licensed Data Product means any Value Added product derived from or
        based on the Licensed Data or any Licensed Data Product. Value Adding
        means any augmenting, repackaging or incorporating of the licensee-s
        data, or other data licensed to the Licensee, with the Licensed Data.
        Conversion of the Licensed Data onto different media or the translation
        into a different format is not Value Adding.
        <br />
        Hardcopy Product means any printed paper based (not in electronic form)
        Licensed Data Product.
      </p> */}

      {/*  <p>
        State of Queensland (Department of Natural Resources and Mines) [2017].
        In consideration of the State permitting use of this data you
        acknowledge and agree that the State gives no warranty in relation to
        the data (including accuracy, reliability, completeness, currency or
        suitability) and accepts no liability (including without limitation,
        liability in negligence) for any loss, damage or costs (including
        consequential damage) relating to any use of the data. Data must not be
        used for direct marketing or be used in breach of the privacy laws.
      </p>

      <p>
        All Licensed Data Products (with the exception of those sent by SMS)
        must bear the following notice:
      </p>

      <p>
        Based on or contains data provided by the State of Queensland
        (Department of Natural Resources and Mines) [2017]. In consideration of
        the State permitting use of this data you acknowledge and agree that the
        State gives no warranty in relation to the data (including accuracy,
        reliability, completeness, currency or suitability) and accepts no
        liability (including without limitation, liability in negligence) for
        any loss, damage or costs (including consequential damage) relating to
        any use of the data. Data must not be used for direct marketing or be
        used in breach of the privacy laws.
      </p>
 */}
      <p>
        <strong>Government of the State of South Australia</strong>
      </p>

      <p>
        Warning – The information contained in this dataset is extracted from
        records of land status and cadastral boundary definition held by the
        Government of South Australia (the State). The information is not
        represented to be accurate, current, complete, or suitable for any
        purpose, at the time of its supply by the State, and may have changed
        since the date of supply by the State. The software by which the
        information is provided is not represented to be error free. No
        responsibility is accepted by the State for any reliance placed by any
        person upon the information, or the software by which it is provided.
        Persons acquiring or using the information and its associated software
        must exercise their independent judgement in doing so.
      </p>

      <p>
        Copyright – Copyright in the information remains with the Crown in right
        of the State of South Australia. The information is reproduced under
        licence from the Crown.
      </p>

      <p>
        Privacy – The information contained in this dataset must not be used for
        the purposes of compiling contact lists, whether personalised or not.
      </p>

      <p>
        <strong>Crown in Right of Tasmania</strong>
      </p>

      <p>
        The data has been used in the product with the permission of the Crown
        in Right of Tasmania. The Crown in Right of Tasmania and its employee
        and agents:
      </p>
      <ol>
        <li>
          give no warranty regarding the data’s accuracy, completeness,
          currency, or suitability for any particular purpose
        </li>
        <li>
          do not accept liability howsoever arising including but not limited to
          negligence for any loss resulting from the use of or reliance upon the
          data.
        </li>
      </ol>

      <p>
        <strong>Australian Capital Territory</strong>
      </p>

      <p>
        The Territory Data is the property of the Australian Capital Territory.
        No part of it may in any form or by any means (electronic, mechanical,
        microcopying, photocopying, recording or otherwise) be reproduced,
        stored in a retrieval system or transmitted without prior permission.
        Enquiries should be directed to:
      </p>

      <p>
        Manager
        <br />
        Customer Services
        <br />
        Environment and Planning Directorate
        <br />
        GPO Box 158
        <br />
        CANBERRA ACT 2601.
      </p>

      <p>
        <strong>Northern Territory</strong>
      </p>

      <p>
        Copyright in the underlying data for the Northern Territory is owned by
        the Northern Territory of Australia represented by the Department of
        Infrastructure, Planning and Environment for which no responsibility is
        accepted.
      </p>

      <p>
        <strong>
          Western Australian Land Information Authority (Landgate)
        </strong>
      </p>

      <ol>
        <li>
          The APM Property Data is derived from Landgate’s location information
        </li>
        <li>&#169; (2020) Western Australian Land Information Authority</li>
      </ol>

      <p>
        <strong>End User Terms</strong>
        <br />
        The End User must:
        <ol>
          <li>
            only use a Value Added Product for its personal or business
            purposes; and
          </li>
          <li>
            not sell, license, hire, let, trade, expose for sale, or derive
            revenue from the Value Added Product or part of the Value Added
            Product, except where an End User is on-providing the Value Added
            Product, in the same format as received from the Licensee, to the
            End User’s customers;
          </li>
        </ol>
      </p>

      <p>
        (b) the End User acknowledges that:
        <br />
        <ol>
          <li>
            the Value Added Product is derived from Landgate’s location
            information, © (2020) Western Australian Land Information Authority;
          </li>
          <li>
            Western Australian Land Information Authority owns all copyright in
            the location information which is protected by the Copyright Act
            1968 (Cth) and apart from any use as permitted under the fair
            dealing provisions of the Copyright Act 1968 (Cth), all other rights
            are reserved and no location information, or part of the location
            information, may be reproduced, distributed, commercialised or
            re-used for any other purpose without the prior written permission
            of Western Australian Land Information Authority (Landgate);
          </li>
          <li>
            the location information that the Value Added Product is derived
            from is provided by Landgate in good faith on an “as is” basis and
            while Landgate has made every effort to ensure the accuracy,
            reliability, completeness and suitability of the location
            information, Landgate:
            <ol type="A">
              <li>
                does not give any guarantee or take any responsibility or accept
                any liability (including liability in negligence) arising from
                or connected to any errors or omissions in the location
                information;
              </li>
              <li>
                accepts no responsibility and disclaims all liability for any
                losses, damages or costs as a result of the End User’s use or
                reliance on the location information;
              </li>
            </ol>
          </li>
          <li>
            reliance on the location information may only be placed on the
            original source documents such as the certificate of title and
            survey plan available from Landgate;
          </li>
          <li>
            it must exercise its own skill and care with respect to the use of
            the location information, and before relying on the location
            information, the End User must carefully consider its relevance to
            its purpose and obtain any professional advice appropriate to its
            particular circumstances;
          </li>
          <li>
            areas and dimensions shown in the location information and Value
            Added Products may be approximate values only and the End User must
            refer to official registered documents, survey plans, diagrams etc.
            available from Landgate for accurate area, dimensions and other
            information; and
          </li>
          <li>
            the location information that the Value Added Product is derived
            from may be subject to Privacy Legislation and contractual
            restriction on its publication and Landgate takes no responsibility
            for any breach of Privacy Legislation by any person in relation to
            the location information;
          </li>
        </ol>
      </p>
      <p>
        (c) the End User consents to its information being collected and
        provided to Landgate for audit and inspection purposes.
      </p>

      <p>Effective as at: 21 January 2020</p>

      {/* <p>
        Copyright - Copyright in all Landgate information and products
        (including data, pages, documents, maps, online graphics, images, web
        pages, audio and video) (-the Information-) is vested in the Western
        Australian Land Information Authority and is protected by the Copyright
        Act 1968. Apart from any use as permitted under the fair dealing
        provisions of the Copyright Act 1968, all other rights are reserved, and
        no Information and products or part thereof may be reproduced by any
        process, distributed, commercialised or reused for any other purpose
        without the prior written permission of the Western Australian Land
        Information Authority.
      </p>

      <p>
        Western Australian Land Information Authority (2017) trading as
        Landgate. Based on information provided by and with the permission of
        the Western Australian Land Information Authority (2017) trading as
        Landgate.
      </p>

      <p>
        The Licensee must ensure that, unless otherwise specified in this
        Agreement:
      </p>

      <ol>
        <li>
          all reproductions and adaptations of the Location Information
          Products, however altered, reformatted or redisplayed, display the
          following proprietary notice:
          <br />
          Western Australian Land Information Authority (Landgate) (2017)
        </li>
        <li>
          all Value Added Products created and distributed by the Licensee
          display the following notice:
          <br />
          Based on information provided by and with the permission of the
          Western Australian Land Information Authority (Landgate) (2017)
        </li>
      </ol> */}
      <div className="p-4 text-center">
        <Button
          className="btn-primary text-uppercase font-weight-bold font-size-sm m-3"
          style={{minWidth: '120px'}}
          variant="outlined"
          color="primary"
          onClick={() => 
            {
              onTermsAgree()
              /* localStorage.setItem('areMapTermsAgreed', true);
            props.history.push('/map'); */
          }
            
          }>
          {' '}
          Agree{' '}
        </Button>
        <Button
          className="btn text-uppercase font-weight-bold font-size-sm m-3"
          variant="outlined"
          style={{minWidth: '120px'}}
          color="primary"
          onClick={() => {
            props.history.goBack();
          }}>
          {' '}
          Disagree{' '}
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.userReducer.userDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsPage);
