import * as API from '../api/apiService';
import * as toastActions from './toast-actions';
import * as loaderActions from './loader-actions';
import {
  SET_USER_DETAILS,
  SET_USER_ACTIVITY,
  SET_USER_LIST,
  SET_CSV_DATA,
  SET_REPORT_DATE
} from './actionTypes';
import { history } from '../App';
import {
  ADMIN,
  AGENT,
  DEFAULT_ERROR_MESSAGE,
  INDIVIDUAL_AGENT,
  PRINCIPAL_AGENT,
} from '../App.config';

export function setUserDetails(userDetails) {
  return {
    type: SET_USER_DETAILS,
    userDetails: userDetails
  };
}

export function setReportDate(reportDate) {
  return {
    type: SET_REPORT_DATE,
    reportDate: reportDate
  }
}



export const getUserDetails = (successCallback) => {
  return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
    API.userDetails().then(
      (result) => {
        dispatch(setUserDetails(result.data));
        dispatch(setReportDate(result.reportDate));
        successCallback && successCallback(result.data);
        dispatch(loaderActions.setLoaderDisplay(false));
      },
      (error) => {
        if (error.response && error.response.status === 440) {
          history.push('/login');
        }
        dispatch(
          toastActions.setToastFail(
            error.response &&error.response && error.response.data &&  error.response.data.message[0]
              ? error.response.data.message[0]
              : DEFAULT_ERROR_MESSAGE
          )
        );
        dispatch(loaderActions.setLoaderDisplay(false));
      }
    );
  };
};

export const agreeTerms = (isTCAgreed) => {
  return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
    let requestBody = {
      isTCAgreed : isTCAgreed
    }
    API.updateProfile(requestBody).then(
      (result) => {
        dispatch(loaderActions.setLoaderDisplay(false));
        dispatch(getUserDetails((userDetails) => {
          if(userDetails.isTCAgreed===true) {
            history.push('/map');
          }
        }));
      }, 
      (error) => {
        dispatch(
          toastActions.setToastFail(
           error.response && error.response.data &&  error.response.data.message[0]
              ? error.response.data.message[0]
              : DEFAULT_ERROR_MESSAGE
          )
        );
        dispatch(loaderActions.setLoaderDisplay(false));
      }
    )
  }
}

export const updateProfile = (profileObj, formData) => {
  return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
    let updateProfilePicture = new Promise((resolve, reject) => {
      if(formData.get('file')){
        resolve(API.uploadProfilePic(formData))
      }
      else{
        resolve('updated')
      }
    })
    let updateProfile = new Promise((resolve,reject) => {
      resolve(
        API.updateProfile(profileObj)
      )
    })
    Promise.all([updateProfilePicture, updateProfile]).then((values) => {
      dispatch(
        getUserDetails((userDetails) => {
          if (userDetails.roles.length === 0) {
            history.push('/Subscriptions');
          } else if (userDetails.roles.includes(ADMIN)) {
            history.push('/user-history');
          } else if (
            userDetails.roles.includes(AGENT) ||
            userDetails.roles.includes(PRINCIPAL_AGENT) ||
            userDetails.roles.includes(INDIVIDUAL_AGENT)
          ) {
            if (userDetails.masterPrincipalDetails.status !== 'active') {
              history.push('/Subscriptions');
            } else {
              if (userDetails.masterPrincipalDetails.postcodes.length > 0) {
                history.push('/map');
              } else {
                history.push('/user-post-code-select');
              }
            }
          }
        })
        )
        dispatch(loaderActions.setLoaderDisplay(false));
    }, (error) => {      
      dispatch(
        toastActions.setToastFail(
         error.response && error.response.data &&  error.response.data.message
            ? error.response.data.message[0]
            : DEFAULT_ERROR_MESSAGE
        )
      );
      dispatch(loaderActions.setLoaderDisplay(false));
    } )
  }
};

export function setUserAcivity(userActivity, totalUserActivityElements) {
  return {
    type: SET_USER_ACTIVITY,
    userActivity: userActivity,
    totalUserActivityElements: totalUserActivityElements
  };
}

export function setCSVData(userActivity, totalUserActivityElements) {
  return {
    type: SET_CSV_DATA,
    userActivity: userActivity
  };
}

export const getUserActivityReport = (
  companyName,
  pageIndex,
  pageSize,
  queryString,
  filter,
  forExcel,
  successCallback
) => {
  return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
    API.getUserActivityReport(
      companyName,
      pageIndex,
      pageSize,
      queryString,
      filter
    ).then(
      (result) => {
        if (forExcel) {
          dispatch(setCSVData(result.data));
          dispatch(loaderActions.setLoaderDisplay(false));
          successCallback && successCallback(result.data);
        } else {
          dispatch(setUserAcivity(result.data, result.totalPages));
          dispatch(loaderActions.setLoaderDisplay(false));
        }
      },
      (error) => {
        let data = [];
        let totalPage = null;

        dispatch(setUserAcivity(data, totalPage));
        dispatch(loaderActions.setLoaderDisplay(false));
      }
    );
  };
};

export function setUserList(userList) {
  return {
    type: SET_USER_LIST,
    userList: userList
  };
}

export const getAllUsers = () => {
  return (dispatch) => {
    dispatch(loaderActions.setLoaderDisplay(true));
    API.getAllUsers().then(
      (result) => {
        dispatch(setUserList(result.data));
        dispatch(loaderActions.setLoaderDisplay(false));
      },
      (error) => {
        dispatch(
          toastActions.setToastFail(
           error.response && error.response.data &&  error.response.data.message[0]
              ? error.response.data.message[0]
              : DEFAULT_ERROR_MESSAGE
          )
        );
        dispatch(loaderActions.setLoaderDisplay(false));
      }
    );
  };
};


export const getMapZoomLevel = (preferenceObj) => {
  return(dispatch)=>{
    API.getMapZoomLevel(preferenceObj)
    .then((result) =>{
    },
    (error)=>{    
    })
  }
}

export const uploadProfilePic = (formData) => {
  return(dispatch)=> {
    API.uploadProfilePic(formData)
     .then((result) => {
     },
     (error)=>{    
     })
  }
}
