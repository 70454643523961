// Sidebar
export const SET_SIDEBAR_TOGGLE_MOBILE =
  'THEME_OPTIONS/SET_SIDEBAR_TOGGLE_MOBILE';
export const SET_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_SIDEBAR_SHADOW';
export const SET_SIDEBAR_STYLE = 'THEME_OPTIONS/SET_SIDEBAR_STYLE';
export const SET_SIDEBAR_FOOTER = 'THEME_OPTIONS/SET_SIDEBAR_FOOTER';
export const SET_SIDEBAR_TOGGLE = 'THEME_OPTIONS/SET_SIDEBAR_TOGGLE';
export const SET_SIDEBAR_FIXED = 'THEME_OPTIONS/SET_SIDEBAR_FIXED';
export const SET_SIDEBAR_USERBOX = 'THEME_OPTIONS/SET_SIDEBAR_USERBOX';

// Header
export const SET_HEADER_FIXED = 'THEME_OPTIONS/SET_HEADER_FIXED';
export const SET_HEADER_SHADOW = 'THEME_OPTIONS/SET_HEADER_SHADOW';
export const SET_HEADER_BG_TRANSPARENT =
  'THEME_OPTIONS/SET_HEADER_BG_TRANSPARENT';
export const SET_HEADER_SEARCH_HOVER = 'THEME_OPTIONS/SET_HEADER_SEARCH_HOVER';
export const SET_HEADER_DRAWER_TOGGLE =
  'THEME_OPTIONS/SET_HEADER_DRAWER_TOGGLE';

// Main content
export const SET_CONTENT_BACKGROUND = 'THEME_OPTIONS/SET_CONTENT_BACKGROUND';
export const SET_THEME_CONFIGURATOR_TOGGLE =
  'THEME_OPTIONS/SET_THEME_CONFIGURATOR_TOGGLE';

// Footer
export const SET_FOOTER_FIXED = 'THEME_OPTIONS/SET_FOOTER_FIXED';
export const SET_FOOTER_SHADOW = 'THEME_OPTIONS/SET_FOOTER_SHADOW';
export const SET_FOOTER_BG_TRANSPARENT =
  'THEME_OPTIONS/SET_FOOTER_BG_TRANSPARENT';

// Page title
export const SET_PAGE_TITLE_STYLE = 'THEME_OPTIONS/SET_PAGE_TITLE_STYLE';
export const SET_PAGE_TITLE_BACKGROUND =
  'THEME_OPTIONS/SET_PAGE_TITLE_BACKGROUND';
export const SET_PAGE_TITLE_SHADOW = 'THEME_OPTIONS/SET_PAGE_TITLE_SHADOW';
export const SET_PAGE_TITLE_ICON_BOX = 'THEME_OPTIONS/SET_PAGE_TITLE_ICON_BOX';
export const SET_PAGE_TITLE_DESCRIPTION =
  'THEME_OPTIONS/SET_PAGE_TITLE_DESCRIPTION';

//Toast component
export const SET_TOAST_SUCCESS = 'SET_TOAST_SUCCESS';
export const SET_TOAST_FAIL = 'SET_TOAST_FAIL';
export const SET_TOAST_WARNING = 'SET_TOAST_WARNING';
export const SET_TOAST_INFO = 'SET_TOAST_INFO';
export const RESET_TOAST = 'RESET_TOAST';

//User
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_USER_ACTIVITY = 'SET_USER_ACTIVITY';

export const SET_USER_LIST = 'SET_USER_LIST';
export const SET_ADMIN_USER_LISTING = 'SET_ADMIN_USER_LISTING';

//Area Codes
export const SET_POSTCODE_LIST = 'SET_POSTCODE_LIST';
export const SET_REQUEST_POSTCODE_LIST = 'SET_REQUEST_POSTCODE_LIST';

//Agent
export const SET_AGENT_LIST = 'SET_AGENT_LIST';

//Plans
export const SET_PLAN_LIST = 'SET_PLAN_LIST';
export const SET_PLAN_BY_ID = 'SET_PLAN_BY_ID';
export const SET_PAYMENT_INTENT_KEY = 'SET_PAYMENT_INTENT_KEY';
export const SET_PLAN_HISTORY = 'SET_PLAN_HISTORY';
export const SET_ASSIGN_PLANS = 'SET_ASSIGN_PLANS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_CURRENT_SUBSCRIPTION_DETAILS =
  'SET_CURRENT_SUBSCRIPTION_DETAILS';
export const SET_CURRENT_TOPUP_DETAILS = 'SET_CURRENT_TOPUP_DETAILS';
export const SET_PLAN_LIST_FOR_ADMIN = 'SET_PLAN_LIST_FOR_ADMIN';

//Master Data
export const SET_LOGIN_REDIRECT_PATH = 'SET_LOGIN_REDIRECT_PATH';
export const SET_STATE_LIST_BY_COUNTRY = 'SET_STATE_LIST_BY_COUNTRY';
export const SET_POSTCODE_LIST_BY_STATE = 'SET_POSTCODE_LIST_BY_STATE';
export const SET_SELECTED_PLAN_BEFORE_LOGIN = 'SET_SELECTED_PLAN_BEFORE_LOGIN';
export const SET_ISCUSTOME_TAB_SELECT = 'SET_ISCUSTOME_TAB_SELECT';

//Loader
export const SET_LOADER_DISPLAY = 'SET_LOADER_DISPLAY';

//Map
export const SET_SALES_PROPERTY_DATA = 'SET_SALES_PROPERTY_DATA';
export const SET_MAP_FILTER_DATA = 'SET_MAP_FILTER_DATA';
export const SET_RENTAL_PROPERTY_DATA = 'SET_RENTAL_PROPERTY_DATA';
export const SET_PROPERTY_DATA_BY_ID = 'SET_PROPERTY_DATA_BY_ID';
export const SET_ALL_PROPERTY_DATA = 'SET_ALL_PROPERTY_DATA';
export const SET_FILTERED_ALL_PROPERTY_DATA = 'SET_FILTERED_ALL_PROPERTY_DATA';
export const SET_SELECTED_TABLE_DATA = 'SET_SELECTED_TABLE_DATA';
export const SET_OLD_FILTERED_ALL_PROPERTY_DATA = 'SET_OLD_FILTERED_ALL_PROPERTY_DATA';

//Admin
export const CONTACT_ADMIN = 'CONTACT_ADMIN';
export const SET_COMPANY_NAME_LIST = 'SET_COMPANY_NAME_LIST';
export const SET_USER_BY_ID="SET_USER_BY_ID"

//CSV data
export const SET_CSV_DATA = 'SET_CSV_DATA';

//Report data
export const SET_FILTER_DATA_START_DATE = 'SET_FILTER_DATA_START_DATE';
export const SET_FILTER_DATA_END_DATE = 'SET_FILTER_DATA_END_DATE';
export const SET_FILTER_DATA_TYPE = 'SET_FILTER_DATA_TYPE';
export const SET_FILTER_DATA_POST_CODE = 'SET_FILTER_DATA_POST_CODE';
export const SET_FILTER_DATA_SUBURB = 'SET_FILTER_DATA_SUBURB';
export const SET_FILTER_DATA_AGENCY_NAME = 'SET_FILTER_DATA_AGENCY_NAME';
export const SET_FILTER_DATA_AGENT_NAME = 'SET_FILTER_DATA_AGENT_NAME';
export const SET_FILTER_DATA_SUMMARY_TYPE = 'SET_FILTER_DATA_SUMMARY_TYPE';
export const SET_FILTER_DATA_IS_AGENT_WISE = 'SET_FILTER_DATA_IS_AGENT_WISE';
export const SET_FILTER_DATA_PROPERTY_TYPE = 'SET_FILTER_DATA_PROPERTY_TYPE';
export const SET_SUMMARY_DATA = 'SET_SUMMARY_DATA';
export const SET_SUMMARY_DATA_WITH_AGENCY = 'SET_SUMMARY_DATA_WITH_AGENCY';
export const SET_SUMMARY_DATA_WITH_AGENT = 'SET_SUMMARY_DATA_WITH_AGENT';
export const SET_SUBURB_LIST = 'SET_SUBURB_LIST';
export const SET_DETAIL_REPORT_DATA = 'SET_DETAIL_REPORT_DATA';
export const SET_WITHDRAWAL_REPORT_DATA = 'SET_WITHDRAWAL_REPORT_DATA';
export const SET_WITHDRAWAL_REPORT_DATA_WITH_AGENCY = 'SET_WITHDRAWAL_REPORT_DATA_WITH_AGENCY';
export const SET_WITHDRAWAL_REPORT_DATA_WITH_AGENT = 'SET_WITHDRAWAL_REPORT_DATA_WITH_AGENT';
export const SET_WITHDRAWAL_REPORT_DETAIL_DATA = 'SET_WITHDRAWAL_REPORT_DETAIL_DATA';
export const SET_POSTCODE_FOR_AUTOCOMPLETE = 'SET_POSTCODE_FOR_AUTOCOMPLETE';
export const SET_AGENCY_AGENT_LIST_FOR_REPORTS = 'SET_AGENCY_AGENT_LIST_FOR_REPORTS';
export const SET_AGENTS_FOR_AUTOCOMPLETE = 'SET_AGENTS_FOR_AUTOCOMPLETE';
export const SET_FILTER_AGENT_ARRAY = 'SET_FILTER_AGENT_ARRAY';
export const SET_FILTER_AGENCY_ARRAY = 'SET_FILTER_AGENCY_ARRAY';
export const SET_SUMMARY_DATA_PO_SUMMARY = 'SET_SUMMARY_DATA_PO_SUMMARY';
export const SET_WITHDRAWAL_REPORT_DATA_PO = 'SET_WITHDRAWAL_REPORT_DATA_PO';

export const SET_LEVEL_ZERO_DATA = 'SET_LEVEL_ZERO_DATA';
export const SET_LEVEL_ONE_DATA = 'SET_LEVEL_ONE_DATA';
export const SET_LEVEL_TWO_DATA = 'SET_LEVEL_TWO_DATA';
export const SET_LEVEL_THREE_DATA = 'SET_LEVEL_THREE_DATA';
export const SET_LEVEL_FOUR_DATA = 'SET_LEVEL_FOUR_DATA';

export const SET_REPORT_DATE = 'SET_REPORT_DATE';

// For clearing Redux state on logout
export const SET_RESET_STATE = 'SET_RESET_STATE';