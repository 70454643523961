export const MAPBOX_MAP_STYLES = [
  { type: 'mapbox://styles/mapbox/streets-v11', text: 'Streets' },
  { type: 'mapbox://styles/mapbox/light-v10', text: 'Light' },
  { type: 'mapbox://styles/mapbox/dark-v10', text: 'Dark' },
  { type: 'mapbox://styles/mapbox/satellite-v9', text: 'Satellite' }
];
export const DEFAULT_MAP_ZOOM = [2];
export const DEFAULT_MAP_CENTER = [145.08282209, -38.00543627];
export const CONTACT_MAIL = 'support@realeads365.com';
export const MAPBOX_ACCESS_TOKEN =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
  'pk.eyJ1Ijoia3VuYWxkaGFpYmFyIiwiYSI6ImNrZWNtd2hybjBqancydm10ajMxYW50ankifQ.TEF3clz2L6FZTJ62xzufGg';
export const DEFAULT_POSTCODES = [
  '3195',
  '3195',
  '3195',
  '3195',
  '3195',
  '3195',
  '3200',
  '3196',
  '3196',
  '3196',
  '3196',
  '3944',
  '3943',
  '6020'
];
export const EMAIL_PATTERN = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const NUMBER_PATTERN = /^[0-9]*$/;
export const SERVER_URL = process.env.REACT_APP_DEV_SERVER /* || 'https://dev-backoffice.realeads365.com' */ || 'https://api.realeads365.com';
// export const SERVER_URL = 'http://192.168.29.138:4000';
export const ENDPOINT = process.env.REACT_APP_ENDPOINT || '/api';
export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  '403583157871-j3qrp2h2iid5a1muln554n1o1061es4p.apps.googleusercontent.com';
export const GOOGLE_CLIENT_SECRETE =
  process.env.REACT_APP_GOOGLE_CLIENT_SECRETE || 'bt01EFUNL0H42DPg9DxUZ4Jn';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
export const USER_ROLES = {
  admin: {
    text: 'Admin'
  },
  'principal-agent': {
    text: 'Principal Agent'
  },
  agent: {
    text: 'Agent'
  },
  'individual-agent': {
    text: 'Individual Agent'
  }
};
export const ADMIN = 'admin';
export const PRINCIPAL_AGENT = 'principal-agent';
export const AGENT = 'agent';
export const INDIVIDUAL_AGENT = 'individual-agent';
export const PLAN_TYPES = {
  yearly: {
    text: 'Annual'
  },
  monthly: {
    text: 'Monthly'
  }
};
export const LISTING_TYPE = [{name: 'All', value: 'both'}, {name: 'Sales', value: 'sales'}, {name: 'Rental', value: 'rental'},]
/* export const STRIPE_PUBLIC_KEY =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
  'pk_test_51Heg5vFvSyCJQ2JKSY0ZjKF9mVv3AcoXMnAUSTY6JnuCaJaItK68a6Tj8hA7lDRV4p3yDig90x2OvRp9ezYhZUdL00NvZH5ULG'; */
export const STRIPE_PUBLIC_KEY =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
  'pk_live_gMzjOF1zywqqSUT8MW2Ikrs200R1jx2jfZ';
export const PLAN_PAYMENT_GST_PERCENTAGE = 10;
export const SUBSCRIPTION_FILTER_POSTCODES = 'postcodes';
export const SUBSCRIPTION_FILTER_AGENTS = 'agents';
export const MAP_AREA_FILTERS = [
  'Hide overlays',
  'Price',
  'Days to sell',
  'Median age',
  'Median monthly mortgage repayments',
  'Median weekly rent',
  'Median weekly household income',
  'Average household size'
];
// export const PROPERTY_TYPE_ARRAY = [
//   {
//     name: 'House',
//     color: '#c12e77'
//   },
//   {
//     name: 'Unit',
//     color: '#fac77c'
//   },
//   {
//     name: 'Commercial',
//     color: '#6e7202'
//   },
//   {
//     name: 'Industrial',
//     color: '#b7e49d'
//   },
//   {
//     name: 'Vacant Land',
//     color: '#2ea682'
//   },

// ];
export const  PROPERTY_TYPE_ARRAY = [
  {
    name: 'House',
    color: '#c12e77'
  },
  {
    name: 'Unit',
    color: '#fac77c'
  },
  {
    name: 'Townhouse',
    color: '#6e7202'
  },
  {
    name: 'Land',
    color: '#2ea682'
  },
    {
    name: 'Other',
    color: '#8c52ae'
  }
]
export const PROPERTY_COLOR_FILTER = {
  Price: [
    '400,000',
    '600,000',
    '800,000',
    '1,000,000',
    '1,200,000',
    '1,400,000',
    '1,600,000',
    '1,800,000',
    '2,000,000',
    ''
  ],
  'Days to sell': ['35', '40', '45', '50', '55', '60', '65', '70', ''],
  'Median age': ['40', '45', '50', '55', '60', '65', ''],
  'Median monthly mortgage repayments': [
    '1,400',
    '1,600',
    '1,800',
    '2,000',
    '2,200',
    '2,400',
    '2,600',
    '2,800'
  ],
  'Median weekly rent': ['200', '250', '300', '350', '400', '450', '500'],
  'Median weekly household income': [
    '800',
    '1,000',
    '1,200',
    '1,400',
    '1,600',
    '1,800',
    '2,000'
  ],
  'Average household size': ['1.8', '2.0', '2.2', '2.4', '2.6', '']
};
export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE_SIZE = 100;
export const ALTERNATE_PAGE_SIZE = 5;
export const MAP_FILTER_COLOR_SHADES = [
  '#008857',
  '#1abd26',
  '#77e030',
  '#e1fe3c',
  '#ffa436',
  '#ff7a2b',
  '#ff051a',
  '#c90011'
];
export const PROPERTY_COLOR_FILTER_SHADES = {
  Price: [
    '$400,000',
    '$600,000',
    '$800,000',
    '$1,000,000',
    '$1,200,000',
    '$1,400,000',
    '$1,600,000',
    '$1,800,000'
  ],
  'Days to sell': ['35', '40', '45', '50', '55', '60', '65', '70'],
  'Median age': ['40', '45', '50', '55', '60', '65', '70', '85'],
  'Median monthly mortgage repayments': [
    '$1,400',
    '$1,600',
    '$1,800',
    '$2,000',
    '$2,200',
    '$2,400',
    '$2,600',
    '$2,800'
  ],
  'Median weekly rent': [
    '$200',
    '$250',
    '$300',
    '$350',
    '$400',
    '$450',
    '$500',
    '$550'
  ],
  'Median weekly household income': [
    '$800',
    '$1,000',
    '$1,200',
    '$1,400',
    '$1,600',
    '$1,800',
    '$2,000',
    '$2,200'
  ],
  'Average household size': [
    '1.8',
    '2.0',
    '2.2',
    '2.4',
    '2.6',
    '2.8',
    '3.0',
    '3.2'
  ]
};