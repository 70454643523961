import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as toastActions from '../../actions/toast-actions';

class ToastComponent extends React.Component{
    constructor(props){
        super(props);
        this.state={};
        this.handleAlertClose = this.handleAlertClose.bind(this);
    }

    handleAlertClose(event, reason){
        this.props.toastActions.resetToast();
    }

    render(){
        return(
            <Snackbar 
                open={this.props.triggerOpen} 
                autoHideDuration={this.props.timeout} 
                onClose={this.handleAlertClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <MuiAlert 
                    onClose={this.handleAlertClose} 
                    elevation={6} 
                    variant="filled" 
                    severity={this.props.severity}
                    style={{color: "#FFFFFF"}}
                >
                    {this.props.message}
                </MuiAlert>
            </Snackbar>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        message: state.toastReducer.message,
        severity: state.toastReducer.severity,
        timeout: state.toastReducer.timeout,
        triggerOpen: state.toastReducer.triggerOpen,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        toastActions: bindActionCreators(toastActions, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastComponent);