import { SET_LOGIN_REDIRECT_PATH, SET_STATE_LIST_BY_COUNTRY, SET_POSTCODE_LIST_BY_STATE, SET_ISCUSTOME_TAB_SELECT, SET_SELECTED_PLAN_BEFORE_LOGIN } from '../actions/actionTypes';
import {store} from '../App';
export default function masterdataReducer(state = {
    loginRedirectPath: null,
    stateListByCountry: [],
    postcodeListByStates: [],
    filterPostCodes: [],
    selectedPlanBeforeLogin:null,
    isCustomTabSelect:false,
}, action) {
    switch (action.type) {
        case SET_LOGIN_REDIRECT_PATH:
            return Object.assign({}, state, {
                loginRedirectPath: action.loginRedirectPath,
            });
        case SET_STATE_LIST_BY_COUNTRY:
            return Object.assign({}, state, {
                stateListByCountry: action.stateListByCountry,
            });
        case SET_POSTCODE_LIST_BY_STATE:
            return Object.assign({}, state, {
                postcodeListByStates: action.postcodeListByStates,
            });
        case "NEW_POSTCODES_SELECTION" :
        return {
            ...state,
            postcodeListByStates: action.payload
        };
        case "FILTER_POST_CODES": 
        if(action.payload === "") return {...state};

        const filterPostCodes = [...state.postcodeListByStates].filter((postCode) => postCode.postcode.startsWith( action.payload));
        
        return {
            ...state,
            filterPostCodes

        }
        case "CLEAR_FILTER_POSTCODES": 
        return {
            ...state,
            filterPostCodes: []
        }
        case SET_SELECTED_PLAN_BEFORE_LOGIN: 
            return Object.assign({}, state,{
                selectedPlanBeforeLogin:action.selectedPlanBeforeLogin
            })
        case SET_ISCUSTOME_TAB_SELECT: 
            return Object.assign({}, state,{
                isCustomTabSelect:action.isCustomTabSelect
            })
        default:
            return state;
    }
}