import { createStore, combineReducers, applyMiddleware } from 'redux';
import reducers from '../reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const appReducers  = combineReducers({...reducers});

const rootReducer = (state, action) => {
  if(action.type === "SET_RESET_STATE"){
    state = undefined
  }
  return appReducers(state, action);
}

export default function configureStore() {
  return createStore(
    rootReducer,
    {},
    process.env.NODE_ENV==="development" ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk),
  );
}
