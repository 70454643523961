import React from 'react';
import { Grid, InputAdornment, Button, TextField } from '@material-ui/core';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import { EMAIL_PATTERN, GOOGLE_CLIENT_ID } from '../../App.config';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authenticationActions from '../../actions/authentication-actions';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: {
        email: '',
        password: ''
      },
      validationErrors: {
        email: '',
        password: ''
      },
      isSubmitClicked: false,
      isAllValid: false
    };
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.checkErrors = this.checkErrors.bind(this);

    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.handelEnterFrom = this.handelEnterFrom.bind(this);
  }

  componentDidMount() {
    if (!localStorage.getItem('token')) {
      this.props.authenticationActions.logout();
    }else{
      this.props.history.push('/')
    }
    /* window.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        this.handleSubmitClick(e);
        window.removeEventListener('keydown', () => {});
      }
    }); */
  }

  /* componentWillUnmount() {
    console.log('exiting login');
  } */

  handelEnterFrom(e) {
    if (e.keyCode === 13) {
        this.handleSubmitClick();
    }
}

  handleInputChange(event) {
    let name = event.target.name;
    let value = event.target.value.trim();
    let inputObject = this.state.inputFields;
    inputObject[name] = value;
    this.setState({ inputFields: inputObject }, () => {
      this.state.isSubmitClicked && this.validateFields();
    });
  }

  checkErrors(callbackFunction) {
    let doErrorExist = false;
    Object.values(this.state.validationErrors).map((error) => {
      if (error) {
        doErrorExist = true;
      }
      return null;
    });
    this.setState({ isAllValid: !doErrorExist }, () => {
      callbackFunction && callbackFunction();
    });
  }

  handleSubmitClick(event) {
    event && event.preventDefault();
    this.setState({ isSubmitClicked: true });
    this.validateFields(() => {
      if (this.state.isAllValid) {
        this.props.authenticationActions.login(
          this.state.inputFields.email,
          this.state.inputFields.password
        );
      }
    });
  }

  validateFields(callbackFunction) {
    let inputObject = this.state.inputFields;
    let inputErrorObject = this.state.validationErrors;
    Object.keys(inputObject).map((fieldName) => {
      if (!inputObject[fieldName]) {
        inputErrorObject[fieldName] = 'Field cannot be empty';
        return null;
      } else {
        switch (fieldName) {
          case 'password':
            inputErrorObject[fieldName] =
              inputObject[fieldName].length < 6
                ? 'Password must be atleast 6 characters long'
                : '';
            break;
          case 'email':
            inputErrorObject[fieldName] = !EMAIL_PATTERN.test(
              inputObject[fieldName]
            )
              ? 'Email not valid'
              : '';
            break;
          default:
            inputErrorObject[fieldName] = '';
        }
      }
      return null;
    });
    this.setState({ validationErrors: inputErrorObject }, () => {
      this.checkErrors(callbackFunction && callbackFunction);
    });
  }

  handleGoogleLogin(data) {
    this.props.authenticationActions.loginWithGoogle(
      data.tokenId,
      data.googleId
    );
  }

  render() {
    return (
      <div className="app-wrapper bg-white ">
        <div className="app-main">
          <div className="app-content p-0">
            <div className=" d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid item md={10} lg={8} xl={4} className="mx-auto">
                    <div className="text-center">
                      <h1 className="display-4 mb-1 font-weight-bold">Login</h1>
                      <p className="font-size-lg mb-0 text-black-50">
                        Fill in the fields below to login to your account
                      </p>
                    </div>
                    {/* <div className="text-center py-4 rounded bg-secondary my-4">
                      <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        buttonText="Login with Google"
                        onSuccess={(data) => {
                          this.handleGoogleLogin(data);
                        }}
                        onFailure={(data) => {
                          console.log(data);
                        }}
                        cookiePolicy={'single_host_origin'}
                        style={{ backgroundColor: 'red' }}
                      />
                    </div> */}
                    <div className="text-center text-black-50 mb-4">
                      {/* or sign in with credentials */}
                    </div>
                    <div>
                      <div className="mb-4">
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="textfield-email"
                          label="Email address"
                          name="email"
                          value={this.state.inputFields.email}
                          onChange={this.handleInputChange}
                          onKeyDown={this.handelEnterFrom}
                          helperText={this.state.validationErrors.email}
                          error={
                            this.state.validationErrors.email ? true : false
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <MailOutlineTwoToneIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="textfield-password"
                          label="Password"
                          type="password"
                          onKeyDown={this.handelEnterFrom}
                          name="password"
                          value={this.state.inputFields.password}
                          onChange={this.handleInputChange}
                          helperText={this.state.validationErrors.password}
                          error={
                            this.state.validationErrors.password ? true : false
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockTwoToneIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center font-size-md">
                        <div></div>
                        <div>
                          <a
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push('/forgot-password');
                            }}
                            className="text-first">
                            Recover password
                          </a>
                        </div>
                      </div>
                      <div className="text-center py-4">
                        <Button
                          className="btn-second font-weight-bold w-50 my-2"
                          onClick={this.handleSubmitClick}>
                          Sign in
                        </Button>
                      </div>
                      <div className="text-center text-black-50 mt-2">
                        Don't have an account?{' '}
                        <a
                          href="#/"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push('/register');
                          }}
                          className="text-first">
                          Sign up
                        </a>
                      </div>
                    </div>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
