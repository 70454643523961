import { CONTACT_ADMIN, SET_COMPANY_NAME_LIST, SET_ADMIN_USER_LISTING, SET_USER_BY_ID} from '../actions/actionTypes';
  
export default function adminReducer(state = {
    contactAdminDetails: null,
    companyNameList: null,
    adminUserList:[],
    totalAdminUserListingPages: null,
    userById: null,
}, action) {
    switch (action.type) {
        case CONTACT_ADMIN:
            return Object.assign({}, state, {
                contactAdminDetails: action.contactAdminDetails,
            });
        case SET_COMPANY_NAME_LIST:
            return Object.assign({}, state, {
                companyNameList: action.companyNameList,
            });
        case SET_ADMIN_USER_LISTING:
            return Object.assign({}, state, {
                adminUserList: action.adminUserList,
                totalAdminUserListingPages: action.totalAdminUserListingPages
            });
        case SET_USER_BY_ID:
            return Object.assign({}, state, {
                userById: action.userById,
            });
        default:
            return state;
    }
}