import { SET_USER_DETAILS, SET_USER_ACTIVITY, SET_USER_LIST, SET_REPORT_DATE } from '../actions/actionTypes';
  
export default function userReducer(state = {
    userDetails: null,
    userList: [],
    userActivity: [],
    totalUserActivityElements: null,
    reportDate: null,
}, action) {
    switch (action.type) {
        case SET_USER_DETAILS:
            return Object.assign({}, state, {
                userDetails: action.userDetails,
            });
        case SET_USER_ACTIVITY:
            return Object.assign({}, state, {
                userActivity: action.userActivity,
                totalUserActivityElements: action.totalUserActivityElements,
            });
        case SET_USER_LIST:
            return Object.assign({}, state, {
                userList: action.userList,
            });
        case SET_REPORT_DATE:
            return Object.assign({}, state, {
              reportDate: action.reportDate
            })
        default:
            return state;
    }
}
