import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Typography,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authenticationActions from 'actions/authentication-actions';
import { USER_ROLES, ADMIN, PRINCIPAL_AGENT, AGENT, INDIVIDUAL_AGENT} from '../../App.config';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
})(Badge);

const HeaderUserbox = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    props.userDetails && localStorage.getItem('token') && <>
      <Button
        variant="text"
        onClick={handleClick}
        className="btn-transition-none text-left p-0 bg-transparent d-flex align-items-center"
        disableRipple>
        {/* <div className="d-block p-0 avatar-icon-wrapper">
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-success badge-circle border-0' }}
            variant="dot">
            <div className="avatar-icon rounded">
              <img src={avatar7} alt="..." />
            </div>
          </StyledBadge>
        </div> */}
        {/* <span className="font-weight-bold pt-2 line-height-1">Name</span> */}

        <div>
        {props.userDetails.firstName
        ? <div className="font-weight-bold font-size-lg pt-2 line-height-1">{props.userDetails.firstName}</div>
        : <div className="font-weight-bold pt-2 line-height-1">{props.userDetails.google.email ? props.userDetails.google.email : props.userDetails.local.email}</div>
        }
          <span className="text-black-50">{props.userDetails.roles.length>0 ? USER_ROLES[props.userDetails.roles[0]].text : ""}</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}>
        <div className="dropdown-menu-lg overflow-hidden p-0">
          {props.userDetails.roles[0] !== 'admin' ? <List
            component="div"
            className="nav-neutral-primary text-left d-block px-3">
            <NavLink to='/map' onClick={handleClose}>
              <ListItem button className="d-block text-left">
              Dashboard
              </ListItem>
            </NavLink>
          </List> : null}
          <div className="d-flex px-3 pt-2 align-items-center justify-content-between">
            <Typography className="text-capitalize pl-1 font-weight-bold text-primary">
              <span>Profile Options</span>
            </Typography>
            {/* <div className="font-size-xs pr-1">
              <Tooltip title="Change settings" arrow>
                <a href="#/" onClick={(e) => e.preventDefault()}>
                  <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                </a>
              </Tooltip>
            </div> */}
          </div>
          <List
            component="div"
            className="nav-neutral-primary text-left d-block px-3 pb-3">
            {/* <ListItem button className="d-block text-left">
              My Account
            </ListItem> */}
            <NavLink to='/profile' onClick={handleClose}>
              <ListItem button className="d-block text-left">
                Profile
              </ListItem>
            </NavLink>
            {props.userDetails && props.userDetails.roles && !props.userDetails.roles.includes(AGENT) && <li>
              <NavLink
                onClick={handleClose}
                to="/plan-history">
               <ListItem button className='d-block text-left'>
                Plan History
               </ListItem>
              </NavLink>
            </li>}
            {props.userDetails && props.userDetails.roles.length > 0 && <>
              <li>
                <NavLink
                  onClick={handleClose}
                  to="/user-history">
                    <ListItem button className='d-block text-left'>
                      User History
                    </ListItem>
                </NavLink>
              </li>
             </>}
             {props.userDetails && props.userDetails.planDetails && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <li>
              <NavLink
                onClick={handleClose}
                to="/user-post-code-select">
                <ListItem button className='d-block text-left'>
                 Postcodes
                </ListItem>
              </NavLink>
            </li>}
            <NavLink to='/change-password' onClick={handleClose}>
              <ListItem button className="d-block text-left">
                Reset Password
              </ListItem>
            </NavLink>
            {/* {props.userDetails.planDetails && <NavLink to='/dashboard' onClick={handleClose}>
              <ListItem button className="d-block text-left">
                Dashboard
              </ListItem>
            </NavLink>} */}
              <ListItem button className="d-block text-left" onClick={()=>{props.authenticationActions.logout('/login')}}>
                Logout
              </ListItem>
          </List>
          {/* <Divider className="w-100" />
          <div className="d-flex py-3 justify-content-center">
            <div className="d-flex align-items-center">
              <div>
                <FontAwesomeIcon
                  icon={['far', 'chart-bar']}
                  className="font-size-xxl text-info"
                />
              </div>
              <div className="pl-3 line-height-sm">
                <b className="font-size-lg">$9,693</b>
                <span className="text-black-50 d-block">revenue</span>
              </div>
            </div>
          </div>
          <Divider className="w-100" />
          <div className="d-block rounded-bottom py-3 text-center">
            <Tooltip arrow title="Facebook">
              <Button
                size="large"
                className="btn-facebook p-0 d-40 font-size-lg text-white">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fab', 'facebook']} />
                </span>
              </Button>
            </Tooltip>
            <Tooltip arrow title="Dribbble">
              <Button
                size="large"
                className="btn-dribbble p-0 d-40 font-size-lg text-white mx-2">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fab', 'dribbble']} />
                </span>
              </Button>
            </Tooltip>
            <Tooltip arrow title="Twitter">
              <Button
                size="large"
                className="btn-twitter p-0 d-40 font-size-lg text-white">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fab', 'twitter']} />
                </span>
              </Button>
            </Tooltip>
          </div> */}
        </div>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.userReducer.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  authenticationActions: bindActionCreators(authenticationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox);
