import React from 'react';
import { Grid, Button} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authenticationActions from '../../actions/authentication-actions';

class VerifyEmail extends React.Component{
  constructor(props){
    super(props);
    this.handleVerify = this.handleVerify.bind(this);
  }
  
  componentDidMount(){
    this.props.authenticationActions.logout()
  }

  handleVerify(){
    this.props.match.params.verificationCode && this.props.authenticationActions.verifyEmail(this.props.match.params.verificationCode)
  }

    render(){
        return (
            <>
              <div className="app-wrapper bg-white min-vh-100">
                <div className="app-main min-vh-100">
                  <div className="app-content p-0">
                    <div className="app-content--inner d-flex align-items-center">
                      <div className="flex-grow-1 w-100 d-flex align-items-center">
                        <div className="bg-composed-wrapper--content py-5">
                          <Grid item md={10} lg={8} xl={4} className="mx-auto">
                            <div className="text-center mb-5">
                              <h1 className="display-4 mb-1 font-weight-bold">
                                Email Verification
                              </h1>
                              <p className="font-size-lg mb-0 text-black-50">
                                Just need to be sure that it is really you!
                              </p>
                            </div>
                            
                            <div className="text-center mb-5">
                              <Button
                                fullWidth
                                onClick={this.handleVerify}
                                className="text-uppercase font-weight-bold font-size-sm mt-4 btn-primary">
                                Verify
                              </Button>
                            </div>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
    }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
