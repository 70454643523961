import React from 'react';
// import PlanHistoryReport from '../PlanHistoryReport';
// import UserHistoryReport from '../UserHistoryReport';
import { connect } from 'react-redux';
// import { AGENT, PRINCIPAL_AGENT, INDIVIDUAL_AGENT } from 'App.config';

function Dashboard(props) {
  return (
    <>
      {/* {props.userDetails && props.userDetails.roles && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <PlanHistoryReport />}
        <UserHistoryReport /> */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h2>Coming Soon!!!</h2>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userDetails: state.userReducer.userDetails
});

export default connect(mapStateToProps)(Dashboard);
