import React, {useState} from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import projectLogo from '../../assets/images/logo.png';
import { List, ListItem,Button, Menu, Hidden, Divider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import HeaderUserbox from '../HeaderUserbox';
import * as authenticationActions from 'actions/authentication-actions';
import { bindActionCreators } from 'redux';
import {history} from '../../App';
import { USER_ROLES, ADMIN, PRINCIPAL_AGENT, AGENT, INDIVIDUAL_AGENT} from '../../App.config';

const SideHeader = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const [anchorElMenu1, setAnchorElMenu1] = useState(null);
  const handleClickMenu1 = (event) => {
    setAnchorElMenu1(event.currentTarget);
  };
  const handleCloseMenu1 = () => {
    setAnchorElMenu1(null);
  };

  return (
    <div
      className={clsx('app-header', {
        'app-header--shadow': headerShadow,
        'app-header--opacity-bg': headerBgTransparent
      })}>
        {/* {localStorage.getItem('token') && <Hidden lgUp><div onClick={toggleSidebarMobile}><KeyboardArrowRightIcon /></div></Hidden>} */}
        
        <div className="pr-5">
          <img
            height="75"
            alt="Realeads"
            src={projectLogo}
            onClick={()=>{history.push('/')}}
          />
        </div>
      <div className="app-header--pane">
      <Hidden lgDown>
        <NavLink to='/'>
            <Button className="btn px-3">
                Home
              </Button>
          </NavLink>
          <NavLink to='/HowItWorks'>
            <Button className="btn px-3">
                How It Works
              </Button>
          </NavLink>
          <NavLink to='/CompetitorLanding'>
            <Button className="btn px-3">
                Competitor and Market Report
              </Button>
          </NavLink>
          <NavLink to='/ProductInclusions'>
            <Button className="btn px-3">
                Product Inclusions
              </Button>
          </NavLink>
          <NavLink to='/GettingStarted'>
            <Button className="btn px-3">
                Getting Started
              </Button>
          </NavLink>
          
          {props.userDetails && props.userDetails.roles.includes(PRINCIPAL_AGENT) 
                   ? (<NavLink
                       to={"/Subscriptions/agents/" + props.userDetails.planDetails.agentNumber}>
                        <Button className="btn">
                        Subscriptions
                        </Button>
                      </NavLink>) 
                   : (<NavLink
                       to="/Subscriptions">
                        <Button className="btn">
                        Subscriptions
                        </Button>
                      </NavLink>)}
          <NavLink to='/ContactUs'>
            <Button className="btn">
                Contact Us
              </Button>
          </NavLink>
          <NavLink to='/FAQs'>
            <Button className="btn">
                FAQ's
              </Button>
          </NavLink>
          <NavLink to='/privacy-policy'>
            <Button className='btn'>
              Privacy Policy
            </Button>
          </NavLink>
      </Hidden>
      <Hidden smDown><HeaderUserbox /></Hidden>
      
      <div className="m-2">
        {!localStorage.getItem('token') && <Hidden smDown>
          <NavLink to='/login'>
            <Button className="btn">
                Login
              </Button>
          </NavLink>
          <NavLink to='/register'>
            <Button className="btn">
                Sign Up
              </Button>
          </NavLink>
        </Hidden>}
        <Hidden xlUp>
          <Button className="btn" onClick={handleClickMenu1}>
            <MenuIcon />
          </Button>
        </Hidden>
          
          <Menu
            anchorEl={anchorElMenu1}
            keepMounted
            open={Boolean(anchorElMenu1)}
            onClose={handleCloseMenu1}
            onClick={handleCloseMenu1}
            classes={{ list: 'p-0' }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <div className="dropdown-menu-xl">
            {localStorage.getItem('token') && 
                <Hidden mdUp>
                 
                  <div>
                    {props.userDetails && props.userDetails.firstName
                    ? <div className="font-weight-bold font-size-lg pt-3 line-height-1 text-center">{props.userDetails.firstName}</div>
                    : <div className="font-weight-bold p-3 line-height-1 text-center">{props.userDetails && props.userDetails.google.email ? props.userDetails.google.email : props.userDetails && props.userDetails.local.email}</div>
                    }
                      <div className="text-black-50 pb-1 text-center">{props.userDetails && props.userDetails.roles.length>0 ? USER_ROLES[props.userDetails.roles[0]].text : ""}</div>
                  </div>
                  <List
                    component="div"
                    className="nav-neutral-primary text-left d-block">
                    <NavLink to='/profile'>
                      <ListItem button className="d-block text-left pt-0">
                        Profile
                      </ListItem>
                    </NavLink>
                    {props.userDetails && props.userDetails.roles && !props.userDetails.roles.includes(AGENT) && <li>
                      <NavLink
                        to="/plan-history">
                      <ListItem button className='d-block text-left pt-0'>
                        Plan History
                      </ListItem>
                      </NavLink>
                    </li>}
                    {props.userDetails && props.userDetails.roles.length > 0 && <>
                      <li>
                        <NavLink
                          to="/user-history">
                            <ListItem button className='d-block text-left pt-0'>
                              User History
                            </ListItem>
                        </NavLink>
                      </li>
                    </>}
                    {props.userDetails && props.userDetails.planDetails && (props.userDetails.roles.includes(PRINCIPAL_AGENT)||props.userDetails.roles.includes(AGENT)||props.userDetails.roles.includes(INDIVIDUAL_AGENT)) && <li>
                      <NavLink
                        to="/user-post-code-select">
                        <ListItem button className='d-block text-left pt-0'>
                        Postcodes
                        </ListItem>
                      </NavLink>
                    </li>}
                    <NavLink to='/change-password'>
                      <ListItem button className="d-block text-left pt-0">
                        Reset Password
                      </ListItem>
                    </NavLink>
                    <NavLink to='/'>
                      <ListItem button className="d-block text-left pt-0" onClick={()=>{props.authenticationActions.logout('/login')}}>
                          <span>Logout</span>
                      </ListItem>
                    </NavLink>
                  </List>
                  <Divider/>
                 </Hidden>}
              <List
                component="div"
                className="nav-list-square nav-neutral-primary">
                  <NavLink
                  to="/">
                    <ListItem button className="d-block text-left pt-0">
                      <span>Home</span>
                    </ListItem>
                  </NavLink>
                  {localStorage.getItem('token') &&
                    <NavLink 
                    to="/dashboard">
                      <ListItem button className="d-block text-left pt-0">
                        <span>Dashboard</span>
                      </ListItem>
                    </NavLink> }
                  <NavLink
                  to="/HowItWorks">
                    <ListItem button className="d-block text-left pt-0">
                      <span>How It Works</span>
                    </ListItem>
                  </NavLink>
                  <NavLink
                  to="/CompetitorLanding">
                    <ListItem button className="d-block text-left pt-0">
                      <span>Competitor and Market Report</span>
                    </ListItem>
                  </NavLink>
                  <NavLink
                  to="/ProductInclusions">
                    <ListItem button className="d-block text-left pt-0">
                    <span>Product Inclusions</span>
                    </ListItem>
                  </NavLink>
                  <NavLink
                  to="/GettingStarted">
                    <ListItem button className="d-block text-left pt-0">
                    <span>Getting Started</span>
                    </ListItem>
                  </NavLink>
                  {props.userDetails && props.userDetails.roles.includes(PRINCIPAL_AGENT) 
                   ? (<NavLink
                       to={"/Subscriptions/agents/" + props.userDetails.planDetails.agentNumber}>
                        <ListItem button className="d-block text-left pt-0">
                         <span>Subscriptions</span>
                        </ListItem>
                      </NavLink>) 
                   : (<NavLink
                       to="/Subscriptions">
                        <ListItem button className="d-block text-left pt-0">
                         <span>Subscriptions</span>
                        </ListItem>
                      </NavLink>)}
                  
                  <NavLink
                  to="/ContactUs">
                    <ListItem button className="d-block text-left pt-0">
                    <span>Contact Us</span>
                    </ListItem>
                  </NavLink>
                  <NavLink
                  to="/FAQs">
                    <ListItem button className="d-block text-left pt-0">
                    <span>FAQ's</span>
                    </ListItem>
                  </NavLink>
                  <NavLink to="/privacy-policy">
                    <ListItem button className='d-block text-left pt-0'>
                      <span>Privacy Policy</span>
                    </ListItem>
                  </NavLink>
                {!localStorage.getItem('token') && <Hidden mdUp>
                    <NavLink
                    to="/login">
                    <ListItem button className="d-block text-left pt-0">
                      <span>Login</span>
                    </ListItem>
                    </NavLink>
                    <NavLink
                    to="/register">
                    <ListItem button className="d-block text-left pt-0">
                      <span>Register</span>
                    </ListItem>
                    </NavLink>
                </Hidden>}
                {/* {localStorage.getItem('token') && <Hidden mdUp>
                  <NavLink to='/'>
                      <ListItem button className="d-block text-left pt-0" onClick={()=>{props.authenticationActions.logout('/')}}>
                          <span>Logout</span>
                      </ListItem>
                  </NavLink>
                </Hidden>} */}
              
              </List>
            </div>
          </Menu>
        </div>
        {/* <button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  userDetails: state.userReducer.userDetails
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  authenticationActions: bindActionCreators(authenticationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideHeader);
